import '@babel/polyfill';
import 'isomorphic-fetch';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import '../node_modules/semantic-ui-css/semantic.min.css';
import './index.css';
//import registerServiceWorker from './registerServiceWorker';


/* polyfills */
import 'core-js/es7/array'

ReactDOM.render(
    <App />, document.getElementById('root'));
/*registerServiceWorker();*/

