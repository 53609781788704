import React, { Component } from "react";

import Drawer from "@material-ui/core/Drawer";
import TextField from "@material-ui/core/TextField";
import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";
import PlaceIcon from "@material-ui/icons/Place";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import FilterSection from "./sections/FilterSection";
import { rangeOptions, filterKeys } from "../../util/constants";

const styles = {
  paper: {
    height: 70,
    boxShadow:
      "0px 2px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)",
  },
  label: {
    fontSize: 10,
    height: 70,
    minHeight: 70,
  },
  lightTooltip: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 11,
  },
  groupNumber: {
    backgroundColor: "#73d7ff",
    borderRadius: 5,
  },
  groupNumberText: {
    color: "black",
  },
};

class MobileView extends Component {
  constructor() {
    super();

    this.state = {
      tabValue: 0,
      open: false,
    };
  }

  handleTooltipClose = () => {
    this.setState({ open: false });
  };

  handleTooltipOpen = () => {
    this.setState({ open: true });
  };

  componentDidMount() {
    // Scroll to top of the page
    window.scroll(0, 0);

    if (
      this.props.searchType[0] === filterKeys.primaryCare ||
      this.props.searchType === filterKeys.primaryCare
    ) {
      this.setState({
        tabValue: 0,
      });
    } else if (
      this.props.searchType[0] === filterKeys.provider ||
      this.props.searchType === filterKeys.provider
    ) {
      this.setState({
        tabValue: 1,
      });
    } else if (
      this.props.searchType[0] === filterKeys.facility ||
      this.props.searchType === filterKeys.facility
    ) {
      this.setState({
        tabValue: 2,
      });
    } else if (
      this.props.searchType[0] === filterKeys.pediatric ||
      this.props.searchType === filterKeys.pediatric
    ) {
      this.setState({
        tabValue: 3,
      });
    }
  }

  handleToggleFilterChange = (event, tabValue) => {
    this.setState({ tabValue });
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.props.handleMobileSearchBtn();
  };

  render() {
    return (
      <div>
        <Drawer
          anchor="top"
          open={this.props.isDrawerOpen}
          onClose={this.props.toggleDrawer}
          onOpen={this.props.toggleDrawer}
          style={
            this.props.isDrawerOpen
              ? { zIndex: 1000, position: "absolute" }
              : {}
          }>
          <form onSubmit={this.onSubmit}>
            <div
              style={{ height: window.innerHeight - 60, overflowY: "scroll" }}>
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    height: 60,
                    backgroundColor: "#216193",
                    color: "white",
                    fontSize: 16,
                    padding: 20,
                    marginBottom: 20,
                    width: "100%",
                    // position: 'fixed',
                    zIndex: 100,
                  }}>
                  Filter and Search Options
                  <IconButton
                    aria-label="Close Drawer."
                    className={this.props.classes.closeDrawer}
                    onClick={this.props.toggleDrawer}>
                    <i
                      className="fas fa-times-circle"
                      style={{
                        color: "white",
                        fontSize: 20,
                        paddingTop: 0,
                        marginTop: 0,
                      }}
                    />
                  </IconButton>
                </div>
              </div>
              <Paper square className={this.props.classes.paper}>
                <Tabs
                  value={this.state.tabValue}
                  onChange={this.handleToggleFilterChange}
                  fullWidth
                  style={{ minHeight: 70 }}
                  indicatorColor="primary"
                  textColor="primary">
                  <Tab
                    label="PRIMARY CARE"
                    onClick={() =>
                      this.props.handleSearchTypeChange(filterKeys.primaryCare)
                    }
                    icon={
                      <img
                        src={require("../../assets/primary-care.png")}
                        style={{
                          height: 20,
                          width: 20,
                          marginTop: 5,
                        }}
                        alt="PRIMARY CARE"
                      />
                    }
                    className={this.props.classes.label}
                  />
                  <Tab
                    label="SPECIALTY CARE"
                    onClick={() =>
                      this.props.handleSearchTypeChange(filterKeys.provider)
                    }
                    icon={
                      <img
                        src={require("../../assets/specialties.png")}
                        style={{
                          height: 20,
                          width: 20,
                          marginTop: 5,
                        }}
                        alt="SPECIALTY CARE"
                      />
                    }
                    className={this.props.classes.label}
                  />
                  <Tab
                    label="FACILITIES"
                    onClick={() =>
                      this.props.handleSearchTypeChange(filterKeys.facility)
                    }
                    icon={
                      <img
                        src={require("../../assets/facilities.png")}
                        style={{
                          height: 20,
                          width: 20,
                          marginTop: 5,
                        }}
                        alt="FACILITIES"
                      />
                    }
                    className={this.props.classes.label}
                  />
                  <Tab
                    label="PEDIATRIC SPECIALTIES"
                    onClick={() =>
                      this.props.handleSearchTypeChange(filterKeys.pediatric)
                    }
                    icon={
                      <img
                        src={require("../../assets/pediatric-specialties-blue.png")}
                        style={{
                          height: 20,
                          width: 20,
                          marginTop: 5,
                        }}
                        alt="PEDIATRIC SPECIALTIES"
                      />
                    }
                    className={this.props.classes.label}
                  />
                </Tabs>
              </Paper>
              <TextField
                id="keywords-placeholder"
                label="Search for Providers by Name or Keyword"
                placeholder="e.g. Dr. Stevens, Eyes, Heart, Fever, etc."
                style={{
                  height: 50,
                  marginTop: 25,
                  width: "90%",
                  marginLeft: "5%",
                }}
                onChange={(e) => this.props.handleSearchTextChange(e)}
                onKeyPress={this.props.handleEnterKeyPress}
                value={this.props.filterString}
                InputProps={{
                  endAdornment: (
                    <InputAdornment variant="filled" position="end">
                      <IconButton aria-label="Search For Keywords.">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <ClickAwayListener onClickAway={this.handleTooltipClose}>
                <Tooltip
                  classes={{ tooltip: this.props.classes.lightTooltip }}
                  title={
                    <img
                      src={require("../../assets/samp-id-card.png")}
                      style={{
                        height: 200,
                        width: 400,
                        marginTop: 0,
                        backgroundColor: "white",
                      }}
                      alt="Sample ID Card"
                    />
                  }
                  placement="bottom-start"
                  onClose={this.handleTooltipClose}
                  open={this.state.open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener>
                  <TextField
                    disabled
                    required
                    className={this.props.classes.groupNumber}
                    InputProps={{
                      className: this.props.classes.groupNumberText,
                    }}
                    InputLabelProps={{
                      // className: this.props.classes.groupNumberText,
                      classes: {
                        root: this.props.classes.groupNumberText,
                        focused: this.props.classes.groupNumberText,
                      },
                    }}
                    id="group-placeholder"
                    label="Group ID"
                    placeholder="Enter a Group ID"
                    fullWidth
                    margin="normal"
                    style={{ height: 50, width: "90%", marginLeft: "5%" }}
                    onChange={(e) => this.props.handleGroupChange(e)}
                    onKeyPress={this.props.handleEnterKeyPress}
                    value={this.props.userGroup}
                    error={this.props.userGroupErr}
                    onClick={this.handleTooltipOpen}
                  />
                </Tooltip>
              </ClickAwayListener>
              <TextField
                id="location-placeholder"
                label="Location"
                required
                placeholder="Enter a Zipcode or City and State."
                fullWidth
                margin="normal"
                style={{ height: 50, width: "90%", marginLeft: "5%" }}
                onChange={(e) => this.props.handleLocationChange(e)}
                onKeyPress={this.props.handleEnterKeyPress}
                value={this.props.userLocation}
                InputProps={{
                  endAdornment: (
                    <InputAdornment variant="filled" position="end">
                      <Tooltip title="Fetch Location" placement="left">
                        <IconButton
                          aria-label="Search For Location."
                          onClick={this.props.handleLocationIconClick}>
                          <PlaceIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                id="range-select"
                select
                label="Range"
                value={
                  typeof this.props.distance == "string"
                    ? parseInt(this.props.distance)
                    : this.props.distance
                }
                onChange={(e) => this.props.handleRangeSelect(e)}
                style={{ width: "90%", height: 50, marginLeft: "5%" }}
                margin="normal">
                {rangeOptions.map((opt) => (
                  <MenuItem key={opt.key} value={opt.value}>
                    {opt.text}
                  </MenuItem>
                ))}
              </TextField>
              <div
                style={{
                  marginTop: 20,
                  marginBottom: 50,
                  width: "90%",
                  marginLeft: "5%",
                }}>
                <FilterSection
                  providerFilters={this.props.providerFilters}
                  facilityFilters={this.props.facilityFilters}
                  primaryCareFilters={this.props.primaryCareFilters}
                  pediatricCareFilters={this.props.pediatricCareFilters}
                  handleSelectedFilters={this.props.handleSelectedFilters}
                  setFilterObjToState={this.props.handleSetFilterObjToState}
                  filterObj={this.props.filterObj}
                  windowHeight={this.props.windowHeight}
                  __loadingFilters__={this.props.__loadingFilters__}
                />
              </div>
            </div>
            <Button
              variant="contained"
              type="submit"
              style={{
                height: 50,
                fontSize: 16,
                backgroundColor: "#216193",
                color: "white",
                minWidth: 140,
                position: "fixed",
                bottom: 20,
                right: 20,
              }}>
              Search
            </Button>
          </form>
        </Drawer>
        <div className="should-hide-appbar">
          <div className={this.props.classes.root}>
            <AppBar position="static" className={this.props.classes.root}>
              <Toolbar>
                <IconButton
                  className={this.props.classes.menuButton}
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={this.props.toggleDrawer}>
                  <i className="fas fa-filter" style={{ fontSize: 16 }} />
                </IconButton>
                <Typography
                  className={this.props.classes.title}
                  variant="h6"
                  color="inherit"
                  noWrap>
                  Provider Search
                </Typography>
                <div className={this.props.classes.grow} />
                <div className={this.props.classes.search}>
                  <div className={this.props.classes.searchIcon}>
                    <IconButton
                      className={this.props.classes.menuButton}
                      color="inherit"
                      onClick={this.props.handleLocationIconClick}>
                      <PlaceIcon style={{ color: "white" }} />
                    </IconButton>
                  </div>
                  <InputBase
                    placeholder="Location..."
                    classes={{
                      root: this.props.classes.inputRoot,
                      input: this.props.classes.inputInput,
                    }}
                    onChange={(e) => this.props.handleLocationChange(e)}
                    onKeyPress={this.props.handleEnterKeyPress}
                    value={this.props.userLocation}
                  />
                </div>
              </Toolbar>
            </AppBar>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(MobileView);
