import React, { Component } from "react";
import { Route, Router, Switch, Redirect } from "react-router-dom";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";

import createHistory from "history/createBrowserHistory";
//import 'babel/polyfill';

import AppView from "./components/views/AppView";
import NotFoundView from "./components/views/NotFoundView";

const history = createHistory();

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#216193",
    },
  },
});

class App extends Component {
  componentDidMount() {
    // To add to window
    // if (window && !window.Promise) {
    // 	window.Promise = Promise;
    // }
  }
  render() {
    return (
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />

        <Router history={history}>
          <div>
            <Switch>
              <Route exact path="/" component={AppView} />
              <Route path="/search" render={() => <Redirect to="/" />} />
              <Route path="/results" render={() => <Redirect to="/" />} />
              <Route path="/filter-wizard" render={() => <Redirect to="/" />} />
              <Route component={NotFoundView} />
            </Switch>
          </div>
        </Router>
      </MuiThemeProvider>
    );
  }
}

export default App;
