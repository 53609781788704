/* eslint-disable eqeqeq */
import React, { Component } from "react";
import GoogleMap from "google-map-react";

import Card from "@material-ui/core/Card";
import Chip from "@material-ui/core/Chip";
import { Popup } from "semantic-ui-react";

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

class ResultsMapItem extends Component {
  getResultId = () => {
    return this.props.providerIndex + 1 + (this.props.page - 1) * 10;
  };

  render() {
    return (
      <Chip
        style={
          this.props.focusedOnMarker &&
          this.props.focusedOnMarker.id == this.props.provider.id
            ? {
                zIndex: 10,
                backgroundColor: "white",
                border: "3px solid #216193",
                color: "#216193",
                fontSize: 14,
                position: "relative",
              }
            : {
                backgroundColor: "#216193",
                color: "white",
                fontSize: 14,
                zIndex: 0,
                position: "relative",
              }
        }
        onClick={this.props.onClick}
        label={this.getResultId()}
      />
    );
  }
}

export default class ResultsMapSection extends Component {
  constructor() {
    super();

    this.state = {
      mapDetailsPopupObj: undefined,
      open: false,
    };
  }

  componentDidMount() {
    this.buildMapDetailsPopupObj();
  }

  shouldComponentUpdate(newProps, newState) {
    if (newProps !== this.props || newState !== this.state) {
      return true;
    }
    return false;
  }

  buildMapDetailsPopupObj() {
    let mapDetailsPopupObj = {};

    if (this.props.searchResults.length) {
      this.props.searchResults.forEach((r, i) => {
        mapDetailsPopupObj[r.id] = false;
      });
    }

    this.setState({
      mapDetailsPopupObj,
    });
  }

  convertAddressToGoogleMapsString = (result) => {
    let mapString = "";
    if (
      result &&
      result.address1 &&
      result.city &&
      result.state &&
      result.zip
    ) {
      mapString =
        result.address1.replace(/(\s)/g, "+") +
        ",+" +
        result.city.replace(/(\s)/g, "+") +
        ",+" +
        result.state +
        "+" +
        result.zip;
    }
    return mapString;
  };

  render() {
    // Need to be able to click on map icon
    return (
      <Card style={{ height: this.props.windowHeight, width: "100%" }}>
        <GoogleMap
          bootstrapURLKeys={{ key: [API_KEY] }}
          center={
            this.props.lat && this.props.lng
              ? this.props.focusedOnMarker
                ? [
                    this.props.focusedOnMarker.lat,
                    this.props.focusedOnMarker.lng,
                  ]
                : [this.props.lat, this.props.lng]
              : { lat: 34.052235, lng: -118.243683 } // default to los angelos ca
          }
          zoom={this.props.focusedOnMarker ? 14 : 10}>
          {this.props.searchResults.map((result, i) => {
            return (
              <Popup
                key={result.id}
                lat={result.locLatitude}
                lng={result.locLongitude}
                style={
                  this.props.searchHidden
                    ? { top: 140, right: 60, minHeight: 150 }
                    : { top: 320, right: 60, minHeight: 150 }
                }
                trigger={
                  <ResultsMapItem
                    onClick={this.handleMarkerClick}
                    providerIndex={i}
                    page={this.props.page}
                    focusedOnMarker={this.props.focusedOnMarker}
                    provider={result}
                  />
                }
                header={
                  result.provFirstName && result.provLastName
                    ? result.provFirstName +
                      " " +
                      result.provLastName +
                      " - " +
                      result.name
                    : result.name
                }
                content={
                  <div>
                    <p>{result.contactPhone}</p>
                    <p>
                      <a
                        target="_blank"
                        href={
                          "https://www.google.com/maps/place/" +
                          this.convertAddressToGoogleMapsString(result)
                        }
                        rel="noopener noreferrer">
                        Directions
                      </a>
                    </p>
                  </div>
                }
                open={
                  this.props.focusedOnMarker &&
                  this.props.focusedOnMarker.id == result.id
                    ? true
                    : false
                }
              />
            );
          })}
        </GoogleMap>
      </Card>
    );
  }
}
