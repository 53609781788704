export const rangeOptions = [
  // { key: 1, text: '10 miles', value: 10 },
  // { key: 2, text: '20 miles', value: 20 },
  // { key: 3, text: '50 miles', value: 50 },
  // { key: 4, text: '100 miles', value: 100 }
	{ key: 1, text: '5 miles', value: 5 },
	{ key: 2, text: '10 miles', value: 10 },
	{ key: 3, text: '15 miles', value: 15 },
	{ key: 4, text: '20 miles', value: 20 },
	{ key: 5, text: '25 miles', value: 25 },
	{ key: 6, text: '30 miles', value: 30 },
	{ key: 7, text: '50 miles', value: 50 },
	{ key: 8, text: '100 miles', value: 100 }
];

export const sortOptions = [
	{ key: 1, text: 'Distance', value: 'distance' },
	{ key: 2, text: 'A-Z', value: 'a-z' },
	{ key: 3, text: 'Z-A', value: 'z-a' }
];

export const filterTypes = {
  // ADD ACTIONS
	ADD_TOGGLED_SINGULAR_FILTER: 'ADD_TOGGLED_SINGULAR_FILTER',
	ADD_TOGGLED_PARENT_AND_CHILD_FILTERS: 'ADD_TOGGLED_PARENT_AND_CHILD_FILTERS',
	ADD_TOGGLED_CHILD_FILTER: 'ADD_TOGGLED_CHILD_FILTER',
  // DELETE ACTIONS
	DELETE_TOGGLED_SINGULAR_FILTER: 'DELETE_TOGGLED_SINGULAR_FILTER',
	DELETE_CHIP_SINGULAR_FILTER: 'DELETE_CHIP_SINGULAR_FILTER',
	DELETE_TOGGLED_PARENT_AND_CHILD_FILTERS: 'DELETE_TOGGLED_PARENT_AND_CHILD_FILTERS',
	DELETE_TOGGLED_CHILD_FILTER: 'DELETE_TOGGLED_CHILD_FILTER'
};

export const filterKeys = {
	primaryCare: 'PrimaryCare',
	provider: 'Provider',
	facility: 'Facility',
	pediatric: 'Pediatric'
};

export const providerTypeOptions = [
  { key: 1, text: "Primary Care Physician (PCP)", value: "PCP" },
  { key: 2, text: "Specialist", value: "SPEC" },
  { key: 3, text: "Ancillary/Facility", value: "FAC" },
];
