import React, { Component } from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";

export default class ErrorView extends Component {
  componentDidMount() {
    // Scroll to top of the page
    window.scroll(0, 0);
  }

  renderErrDetails() {
    let msg = "";
    if (!this.props.err) {
      msg = "There was a problem. Please try again.";
    } else if (this.props.err.response) {
      msg = this.props.err.response || JSON.stringify(this.props.err.response);
    } else if (this.props.err.err) {
      msg = (
        <div>
          <span style={{ fontWeight: "bold" }}>Message:</span>{" "}
          {this.props.err.err.message}
          <br />
          <span style={{ fontWeight: "bold", marginTop: 40 }}>Stack:</span>{" "}
          {this.props.err.err.stack}
        </div>
      );
    }

    if (typeof msg == "object" && msg.status && msg.statusText) {
      return (
        <div>
          <div style={{ fontSize: 16, marginTop: 20 }}>
            Error Code: {msg.status}
          </div>
          <div style={{ fontSize: 16, marginTop: 10, marginBottom: 20 }}>
            {msg.statusText}
          </div>
        </div>
      );
    }
    return <div style={{ fontSize: 16, marginTop: 40 }}>{msg}</div>;
  }

  render() {
    return (
      <Card
        style={{
          marginTop: 100,
          marginLeft: "10%",
          width: "80%",
          height: 300,
        }}>
        <CardContent>
          <h2>Oops, there was an error with Provider Search.</h2>
          <hr />
          {this.renderErrDetails()}
          <hr />
          <br />
          <Button
            variant="contained"
            style={{
              height: 50,
              fontSize: 14,
              marginTop: 30,
              backgroundColor: "#216193",
              color: "white",
              minWidth: 140,
              width: "50%",
              marginLeft: "25%",
            }}
            onClick={() => window.location.reload(true)}>
            Reload
          </Button>
        </CardContent>
      </Card>
    );
  }
}
