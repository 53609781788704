const formatTaxId = (taxID) => {
  const cleaned = taxID.replace(/-/g, "");
  const match = cleaned.match(/(\d{2})(\d{0,7})$/);

  if (match) {
    if (cleaned.length === 9) {
      return [true, match[1].concat("-", match[2])];
    }
    return [false, match[1].concat("-", match[2])];
  }

  return [false, cleaned];
};

export default formatTaxId;
