import React, { useState } from "react";
import { FormMode } from "./enums";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  dialogTitle: {
    marginBottom: 0,
    paddingBottom: 0,
  },
  dialogContextText: {
    marginBottom: 0,
    paddingBottom: 0,
  },
  dialogContent: {
    marginTop: 0,
    paddingTop: 0,
  },
  formControl: {
    marginTop: 16,
    marginRight: 8,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: 16,
  },
  textField: {
    marginTop: 16,
    marginRight: 8,
  },
  dateField: {
    marginTop: 16,
    marginRight: 8,
    width: 150,
  },
  menu: {
    width: 200,
  },
  wrapper: {
    margin: 8,
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
};

const getFormTitleText = (formMode) => {
  switch (formMode) {
    case FormMode.Create:
      return "Create";
    case FormMode.Edit:
      return "Edit";
    case FormMode.Submit:
    default:
      return "Submit";
  }
};

const getSubmitButtonText = (formMode) => {
  switch (formMode) {
    case FormMode.Create:
      return "Add New";
    case FormMode.Edit:
      return "Edit";
    case FormMode.Submit:
    default:
      return "Submit";
  }
};

const DialogFormWrapper = ({
  dialogOpen,
  formMode,
  formComponent: FormComponent,
  dialogTitle = "",
  formTitle,
  hasDelete,
  onDialogClose,
  ...rest
}) => {
  const classes = styles;
  const childForm = React.createRef();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formDeleting, setFormDeleting] = useState(false);

  return (
    <Dialog
      open={dialogOpen}
      onClose={onDialogClose}
      onClick={(e) => {
        e.stopPropagation(); // Capture clicks and stop them from reaching parent nodes. Fix for Acordian bug.
      }}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      scroll="body">
      <DialogTitle id="form-dialog-title" style={classes.dialogTitle}>
        {/* Get this title from the child form or from props?  Perhapps change where this logic resides? */}
        {dialogTitle !== "" ? dialogTitle : getFormTitleText(formMode)}
      </DialogTitle>

      <DialogContent style={classes.dialogContent}>
        <DialogContentText style={classes.dialogContextText}>
          {formTitle}
        </DialogContentText>

        {/* ##########
            CHILD FORM
            ########## */}
        <FormComponent
          ref={childForm}
          formMode={formMode}
          onDialogClose={onDialogClose}
          setFormSubmitting={setFormSubmitting}
          setFormDeleting={setFormDeleting}
          {...rest}
        />
      </DialogContent>

      <DialogActions style={{ display: "flex" }}>
        {hasDelete && formMode !== FormMode.Create && (
          <div style={{ ...classes.wrapper, marginRight: "auto" }}>
            <Button
              onClick={() => childForm.current.handleDelete()} // Call Child's Delete Function
              disabled={formDeleting}
              color="secondary">
              DELETE
            </Button>
            {formDeleting && (
              <CircularProgress
                style={{ ...classes.buttonProgress, color: "#f00" }}
                size={24}
              />
            )}
          </div>
        )}

        <Button onClick={onDialogClose} style={{ color: "#999" }}>
          Cancel
        </Button>

        <div style={classes.wrapper}>
          <Button
            onClick={() => childForm.current.handleSubmit(formMode)} // Call Child's Submit Function
            disabled={formSubmitting}
            variant="contained"
            color="primary"
            autoFocus>
            {getSubmitButtonText(formMode)}
          </Button>
          {formSubmitting && (
            <CircularProgress style={classes.buttonProgress} size={24} />
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default DialogFormWrapper;
