const formatPhoneNumber = (phoneNumber) => {
  const cleaned =
    typeof phoneNumber === "string"
      ? phoneNumber.replace(/\D/g, "")
      : (phoneNumber && phoneNumber[1] && phoneNumber[1].replace(/\D/g, "")) ||
        "";
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [
      true,
      [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(""),
    ];
  }
  return [
    false,
    typeof phoneNumber === "string"
      ? phoneNumber
      : (phoneNumber && phoneNumber[1]) || "",
  ];
};

export default formatPhoneNumber;
