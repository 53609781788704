/* eslint-disable eqeqeq */
import React, { Component } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import "isomorphic-fetch";

import HeaderSection from "./sections/HeaderSection";
import FilterSection from "./sections/FilterSection";
import ResultsListSection from "./sections/ResultsListSection";
import ResultsMapSection from "./sections/ResultsMapSection";
import SplashView from "./SplashView";
import MobileView from "./MobileView";
import ErrorView from "./ErrorView";
import { buildQueryParams } from "../../util/util";
import {
  filterTypes,
  filterKeys,
  providerTypeOptions,
} from "../../util/constants";
import formatPhoneNumber from "../../util/formatPhoneNumber";
import formatTaxId from "../../util/formatTaxId";
// FORM IMPORTS
import { FormMode } from "../../forms/enums";
import DataIssueFeedbackForm from "../../forms/DataIssueFeedbackForm";
import DialogFormWrapper from "../../forms/DialogFormWrapper";
import MenuButton from "../MenuButton";

const styles = (theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#216193",
  },
  closeDrawer: {
    position: "absolute",
    right: 20,
    top: 10,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: "none",
    fontSize: 18,
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 8,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 300,
      },
    },
  },
});

const defaultFormState = {
  RequestorNameVal: "",
  RequestorNameErr: false,
  RequestorEmailVal: "",
  RequestorEmailErr: false,
  RequestorPhoneVal: "",
  RequestorPhoneErr: false,
  GeographicAreaVal: "",
  GeographicAreaErr: false,
  LegalBusinessNameVal: "",
  LegalBusinessNameErr: false,
  DoingBusinessAsVal: "",
  DoingBusinessAsErr: false,
  GroupNPIVal: "",
  GroupNPIErr: false,
  TaxIdVal: "",
  TaxIdErr: false,
  ProviderTypeVal: "",
  ProviderTypeErr: false,
  ProviderNameVal: "",
  ProviderNameErr: false,
  SpecialtyVal: "",
  SpecialtyErr: false,
  IndividualNPIVal: "",
  IndividualNPIErr: false,
  PracticeAddressVal: "",
  PracticeAddressErr: false,
};

class FindADoctorDialog extends Component {
  constructor() {
    super();

    this.state = {
      __submittingNominationForm__: false,
      ...defaultFormState,
      formHasErrors: false,
      isEmailInvalid: false,
      isErrView: undefined,
      isFormSentSuccess: false,
      networks: [],
    };
    this.providerApiPath = process.env.REACT_APP_API_URL + "/provider";
    this.networkApiPath = process.env.REACT_APP_API_URL + "/network/networks";
  }

  fetchNetworks = () => {
    return fetch(this.networkApiPath, {
      method: "get",
      headers: {
        dataType: "json",
        contentType: "application/json",
      },
    })
      .then((response) => {
        if (
          response.status !== 200 &&
          response.status !== 201 &&
          response.status !== 201
        ) {
          console.log(
            "Error: Failed to fetch filters. Status Code: " + response.status,
          );
          return;
        }
        response.json().then((res) => {
          this.setState({
            networks: res,
          });
          return;
        });
      })
      .catch((err) => {
        console.log("Error fetching networks.");
        this.setState({
          isErrView: { flag: true, err: err },
        });
      });
  };

  componentDidMount() {
    this.fetchNetworks();
    // should load in ../App.js?
    // loadReCaptcha(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY);
  }

  handleChangePhoneNumber = (stateKey, errorKey) => (event) => {
    const [isValid, phoneNumber] = formatPhoneNumber(
      event.target.value.replace(/[^\d()\s-]/gi, ""),
    );

    this.setState({
      [stateKey]: phoneNumber,
      [errorKey]: !isValid,
    });
  };

  handleChangeTaxId = (stateKey, errorKey) => (event) => {
    const [isValid, taxID] = formatTaxId(
      event.target.value.replace(/[^\d()\s-]/gi, ""),
    );

    this.setState({
      [stateKey]: taxID,
      [errorKey]: !isValid,
    });
  };

  handleChange = (stateKey) => (event) => {
    if (`${stateKey}Err`) {
      this.setState({
        [`${stateKey}Err`]: false,
      });
    }

    this.setState({
      [stateKey]: event.target.value,
    });
  };

  handleEnterKeyPress = (e) => {
    if (e.key == "Enter" || e.keyCode == 13) {
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    const _this = this;

    const handleErrs = (stateKey) => {
      if (!this.state[`${stateKey}Val`]) {
        this.setState({
          [`${stateKey}Err`]: true,
          formHasErrors: true,
        });
      } else {
        this.setState({
          [`${stateKey}Err`]: false,
        });
      }
    };

    const stateKeys = [
      "RequestorName",
      "RequestorEmail",
      "RequestorPhone",
      "GeographicArea",
      "LegalBusinessName",
      "DoingBusinessAs",
      "TaxId",
      "ProviderType",
      "Specialty",
      "PracticeAddress",
    ];

    stateKeys.forEach((e) => {
      handleErrs(e);
    });

    if (
      this.state.RequestorNameVal &&
      this.state.RequestorEmailVal &&
      this.state.RequestorPhoneVal &&
      this.state.GeographicAreaVal &&
      this.state.LegalBusinessNameVal &&
      this.state.DoingBusinessAsVal &&
      this.state.TaxIdVal &&
      this.state.ProviderTypeVal &&
      this.state.SpecialtyVal &&
      this.state.PracticeAddressVal
    ) {
      // validating email
      function validateEmail(email) {
        var re =
          /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }

      const isEmailValid = validateEmail(this.state.RequestorEmailVal);

      if (!isEmailValid) {
        this.setState({
          RequestorEmailErr: true,
          isEmailInvalid: true,
          formHasErrors: true,
        });
      } else {
        this.setState({
          __submittingNominationForm__: true,
          RequestorEmailErr: false,
          isEmailInvalid: false,
          formHasErrors: false,
        });

        let formData = {
          // practice data
          ProviderName: this.state.ProviderNameVal,
          ProviderSpecialty: this.state.SpecialtyVal,
          ProviderNPI: this.state.IndividualNPIVal,
          ProviderAddress: this.state.PracticeAddressVal,
          // user data
          MemberName: this.state.RequestorNameVal,
          MemberEmail: this.state.RequestorEmailVal,
          ProviderPhone: this.state.RequestorPhoneVal,
          NetworkID: this.state.GeographicAreaVal,
          PracticeName: this.state.LegalBusinessNameVal,
          EmployerName: this.state.DoingBusinessAsVal,
          GroupNPI: this.state.GroupNPIVal,
          PracticeTIN: this.state.TaxIdVal,
          ProviderType: this.state.ProviderTypeVal,
        };

        return fetch(_this.providerApiPath + "/nominations", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        })
          .then((response) => {
            if (response.status !== 200 && response.status !== 201) {
              _this.setState({
                __submittingNominationForm__: false,
              });
              console.log(
                "Looks like there was a problem. Status Code: " +
                  response.status,
              );
              response.json().then((res) => {
                alert(
                  res ||
                    "There was an error sending your request. Please try again.",
                );
              });
            } else {
              _this.setState(
                {
                  __submittingNominationForm__: false,
                  formSentSuccess: true,
                },
                () => {
                  setTimeout(() => {
                    // close dialog
                    _this.props.closeDialog();

                    // refresh state
                    _this.setState({
                      ...defaultFormState,
                      formHasErrors: false,
                      isEmailInvalid: false,
                      formSentSuccess: false,
                    });
                  }, 500);
                },
              );
            }
          })
          .catch((err) => {
            console.log("Error submitting nomination form: ", err);
            _this.setState({
              isErrView: { flag: true, err: err },
            });
            return;
          });
      }
    }
  };

  verifyRecaptchaCallback = (recaptchaToken) => {
    const _this = this;
    if (recaptchaToken) {
      return fetch(_this.providerApiPath + "/verifyNominationRecaptcha", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: recaptchaToken,
          action: process.env.REACT_APP_GOOGLE_RECAPTCHA_NOMINATION_ACTION,
        }),
      })
        .then((response) => {
          if (response.status !== 200 && response.status !== 201) {
            console.log(
              "Looks like there was a problem. Status Code: " + response.status,
            );
            alert("There was an error verifying your recaptcha token.");
          } else {
            response.json().then((res) => {
              // under 0.4 kick user out -- i believe google handles
            });
          }
        })
        .catch((err) => {
          console.log("Error fetching providers: ", err);
          _this.setState({
            isErrView: { flag: true, err: err },
          });
          return;
        });
    } else {
      _this.setState({
        isErrView: {
          flag: true,
          err: "There was an error verifying your recaptcha token. Please refresh the page and try again.",
        },
      });
      return;
    }
  };

  render() {
    let view = <div />;

    if (this.state.isErrView) {
      view = (
        <div style={{ height: window.innerHeight }}>
          <ErrorView err={this.state.isErrView} />;
        </div>
      );
    } else {
      view = (
        <div style={{ zIndex: 20 }}>
          <Dialog
            open={this.props.isOpen}
            onClose={this.props.closeDialog}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Nominate Provider</DialogTitle>
            <DialogContent>
              <p>
                If there is a provider you cannot find, please take a moment to
                complete this information. Your provider recommendation will be
                considered for addition to the network. A representative will
                contact you regarding the status.
              </p>
              <DialogContentText
                style={
                  this.state.formHasErrors
                    ? { color: "red" }
                    : { color: "grey" }
                }>
                Please fill out all fields.
                <div style={{ marginTop: 10, fontSize: 10 }}>
                  {this.state.isEmailInvalid ? "- Email is not valid." : null}
                </div>
              </DialogContentText>
              <div style={{ fontSize: 14, fontWeight: "bold", marginTop: 20 }}>
                Your Information
              </div>
              <TextField
                required
                value={this.state.RequestorNameVal}
                error={this.state.RequestorNameErr}
                margin="dense"
                id="username"
                label="Requestor Name"
                onChange={this.handleChange("RequestorNameVal")}
                onKeyPress={this.handleEnterKeyPress}
                fullWidth
                inputProps={{ maxLength: 75 }}
              />
              <TextField
                required
                value={this.state.RequestorEmailVal}
                error={this.state.RequestorEmailErr}
                margin="dense"
                id="user-email"
                label="Requestor Email"
                onChange={this.handleChange("RequestorEmailVal")}
                onKeyPress={this.handleEnterKeyPress}
                fullWidth
                inputProps={{ maxLength: 100 }}
              />
              <TextField
                required
                type="tel"
                value={this.state.RequestorPhoneVal}
                error={this.state.RequestorPhoneErr}
                margin="dense"
                id="requestor-phone"
                label="Requestor/Practice Phone"
                onChange={this.handleChangePhoneNumber(
                  "RequestorPhoneVal",
                  "RequestorPhoneErr",
                )}
                onKeyPress={this.handleEnterKeyPress}
                fullWidth
                inputProps={{ maxLength: 25 }}
              />
              <TextField
                required
                select
                label="Geographic Area/Market"
                value={this.state.GeographicAreaVal}
                error={this.state.GeographicAreaErr}
                onChange={this.handleChange("GeographicAreaVal")}
                style={{ width: "100%", height: 40 }}
                margin="normal">
                {this.state.networks.map((opt) => (
                  <MenuItem key={opt.networkId} value={opt.networkId}>
                    {opt.description}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                value={this.state.LegalBusinessNameVal}
                error={this.state.LegalBusinessNameErr}
                margin="dense"
                id="legal-name"
                label="Legal Business Name (W9)"
                onChange={this.handleChange("LegalBusinessNameVal")}
                onKeyPress={this.handleEnterKeyPress}
                fullWidth
                inputProps={{ maxLength: 150 }}
              />
              <TextField
                required
                value={this.state.DoingBusinessAsVal}
                error={this.state.DoingBusinessAsErr}
                margin="dense"
                id="bussiness-as"
                label="Doing Business As (DBA)"
                onChange={this.handleChange("DoingBusinessAsVal")}
                onKeyPress={this.handleEnterKeyPress}
                fullWidth
                inputProps={{ maxLength: 75 }}
              />
              <TextField
                value={this.state.GroupNPIVal}
                error={this.state.GroupNPIErr}
                margin="dense"
                id="group-npi"
                label="Group NPI#"
                onChange={this.handleChange("GroupNPIVal")}
                onKeyPress={this.handleEnterKeyPress}
                fullWidth
                inputProps={{ maxLength: 10 }}
              />
              <TextField
                required
                value={this.state.TaxIdVal}
                error={this.state.TaxIdErr}
                margin="dense"
                id="tax-id"
                label="Tax ID#"
                onChange={this.handleChangeTaxId("TaxIdVal", "TaxIdErr")}
                onKeyPress={this.handleEnterKeyPress}
                fullWidth
                inputProps={{ maxLength: 10 }}
              />
              <TextField
                required
                select
                label="Provider Type"
                value={this.state.ProviderTypeVal}
                error={this.state.ProviderTypeErr}
                onChange={this.handleChange("ProviderTypeVal")}
                style={{ width: "100%", height: 40 }}
                margin="normal">
                {providerTypeOptions.map((opt) => (
                  <MenuItem key={opt.key} value={opt.value}>
                    {opt.text}
                  </MenuItem>
                ))}
              </TextField>
              <div style={{ fontSize: 14, fontWeight: "bold", marginTop: 20 }}>
                Provider Information
              </div>
              <TextField
                value={this.state.ProviderNameVal}
                error={this.state.ProviderNameErr}
                margin="dense"
                id="doctor-name"
                label="Doctor Name"
                onChange={this.handleChange("ProviderNameVal")}
                onKeyPress={this.handleEnterKeyPress}
                fullWidth
                inputProps={{ maxLength: 75 }}
              />
              <TextField
                required
                value={this.state.SpecialtyVal}
                error={this.state.SpecialtyErr}
                margin="dense"
                id="speciality"
                label="Speciality"
                onChange={this.handleChange("SpecialtyVal")}
                onKeyPress={this.handleEnterKeyPress}
                fullWidth
                inputProps={{ maxLength: 50 }}
              />
              <TextField
                value={this.state.IndividualNPIVal}
                error={this.state.IndividualNPIErr}
                margin="dense"
                id="group-npi"
                label="Individual NPI#"
                onChange={this.handleChange("IndividualNPIVal")}
                onKeyPress={this.handleEnterKeyPress}
                fullWidth
                inputProps={{ maxLength: 10 }}
              />
              <TextField
                required
                value={this.state.PracticeAddressVal}
                error={this.state.PracticeAddressErr}
                margin="dense"
                id="address"
                label="Practice Address"
                onChange={this.handleChange("PracticeAddressVal")}
                onKeyPress={this.handleEnterKeyPress}
                fullWidth
                inputProps={{ maxLength: 150 }}
              />
            </DialogContent>
            <DialogActions style={{ marginRight: 20 }}>
              <Button
                onClick={() =>
                  this.setState(
                    {
                      ...defaultFormState,
                      formHasErrors: false,
                      isEmailInvalid: false,
                    },
                    () => this.props.closeDialog(),
                  )
                }
                variant="outlined">
                Cancel
              </Button>
              <Button
                disabled={this.state.__submittingNominationForm__}
                onClick={this.handleSubmit}
                variant="contained"
                // ERRORS with these styles
                style={
                  this.state.__submittingNominationForm__
                    ? {
                        backgroundColor: "lightgrey",
                        color: "white",
                        width: 110,
                      }
                    : { backgroundColor: "#216193", color: "white", width: 110 }
                }>
                {this.state.isFormSentSuccess ? "Success!" : "Submit"}
                {this.state.__submittingNominationForm__ ? (
                  <CircularProgress
                    size={24}
                    style={{
                      color: "green",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: -12,
                      marginLeft: -12,
                    }}
                  />
                ) : null}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }

    return <div>{view}</div>;
  }
}

class FindADoctorMemberDialog extends Component {
  render() {
    let view = <div />;

    view = (
      <div style={{ zIndex: 20 }}>
        <Dialog
          open={this.props.isOpen}
          onClose={this.props.closeDialog}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            Please contact Member Services on the back of your ID card to help
            locate a Provider.
          </DialogTitle>
          <DialogActions style={{ marginRight: 20 }}>
            <Button onClick={this.props.closeDialog} variant="outlined">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );

    return <div>{view}</div>;
  }
}

class AppView extends Component {
  constructor() {
    super();

    this.state = {
      // loaders -----------
      __loadingApp__: true,
      __loadingUserLocation__: true,
      __loadingFilters__: true,
      __loadingSearchResults__: true,
      // filter arrays ----------
      providerFilters: [],
      facilityFilters: [],
      primaryCareFilters: [],
      pediatricCareFilters: [],
      // app meta data ----------
      searchResults: [],
      filterObj: {},
      fuzzyCount: 0,
      resultsCount: 0,
      pageCount: 0,
      groupNumber: "",
      userGroup: "1008109",
      userGroupErr: false,
      userLocation: "",
      focusedOnMarker: undefined,
      sectionsContainerOnTop: false,
      isErrView: undefined,
      isSplashView: true,
      isDrawerOpen: false,
      isMobileSearch: false,
      isMobileView: false,
      isCantFindADoctorDialogOpen: false,
      isCantFindADoctorMemberDialogOpen: false,
      showFilterStringLabel: false,
      showResultsLocationMsg: false,
      didUserDenyLocationPrivledges: false,
      showMobileMapView: false,
      // query params ----------
      //distance: 20,
      distance: 5,
      sort: "distance",
      filterString: "",
      network: "",
      fiscalYear: "",
      searchType: [filterKeys.provider],
      specialty: [],
      page: 1,
      lat: undefined,
      lng: undefined,
      size: 10, // size
      formHasErrors: false,

      dataIssueDialogOpen: false,
      selectedProvider: {
        id: "",
        dataType: "",
        provProfessionalId: 0,
        provFirstName: "",
        provLastName: "",
        provGender: "",
        name: "",
        providerSpecialty: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        contactPhone: "",
        other: "",
      },
    };

    this.providerApiPath = process.env.REACT_APP_API_URL + "/providerByGroup";
    this.googleGeoApiPath =
      "https://maps.googleapis.com/maps/api/geocode/json?";

    // TODO: got to be a better way than this -- use state instead....
    this.DONT_HIDE_SEARCH = false;
    this.HIDE_SEARCH = true;
  }

  componentDidMount() {
    const _this = this;
    const mobileBreakpoint = 1100;

    // scroll to top of page
    window.scroll(0, 0);

    // TOOD: fade in search button on scroll down
    // const handleScrollEvent = () => {
    // 	// alert('scroll');
    // };

    // // Scroll listener
    // window.addEventListener('scroll', handleScrollEvent);

    const handleResizeEvent = () => {
      if (window.innerWidth <= mobileBreakpoint) {
        // For any js styling for mobile search -- mobile view styles
        _this.setState({
          isMobileView: true,
        });

        // const elements = document.getElementsByClassName('grecaptcha-badge');
        // if ($('.grecaptcha-badge-visible') || elements) {
        // 	$('.grecaptcha-badge-visible').removeClass('grecaptcha-badge-visible').addClass('grecaptcha-badge');
        // }
      } else {
        // desktop view styles
        _this.setState({
          isMobileView: false,
          showMobileMapView: false,
        });

        // const elements = document.getElementsByClassName('grecaptcha-badge');
        // // $(".grecaptcha-badge").appendTo(".jss11.jss37.jss12.jss50.jss51.jss54")
        // if ($('.grecaptcha-badge') || elements) {
        // 	$('.grecaptcha-badge').removeClass('grecaptcha-badge').addClass('grecaptcha-badge-visible');
        // }
      }
    };

    // listen for dynamic resizing - recursive case
    window.addEventListener("resize", handleResizeEvent);

    // handles base mobile case on load -- using a combo of media queries and js
    if (window.innerWidth <= mobileBreakpoint) {
      this.setState({
        isMobileView: true,
      });

      // const elements = document.getElementsByClassName('grecaptcha-badge');
      // if ($('.grecaptcha-badge-visible') || elements) {
      // 	$('.grecaptcha-badge-visible').removeClass('grecaptcha-badge-visible').addClass('grecaptcha-badge');
      // }
    }

    // Check if URL params exist
    let doParamsExist = false;
    Object.keys(this.state).forEach((p) => {
      if (this.props.history.location.search.indexOf(p) > -1) {
        doParamsExist = true;
      }
    });

    if (doParamsExist) {
      this.buildAppFromUrlParams();
    } else {
      this.buildApp();
    }
  }

  handleDataIssueButtonClick = (e, entity) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ selectedProvider: entity }, () =>
      this.handleDataIssueFeedbackFormDialogOpen(),
    );
  };

  handleDataIssueFeedbackFormDialogOpen = () => {
    this.setState({ dataIssueDialogOpen: true });
  };

  handleDataIssueFeedbackFormClose = () => {
    this.setState({ dataIssueDialogOpen: false });
  };

  buildApp() {
    // Fetch user location -- TODO: why on promise callback instead of the fetchUserLocation()??
    this.fetchUserLocation()
      .then((userLocation) => {
        this.setState({
          __loadingUserLocation__: false,
          userLocation,
        });

        // Fetch initial filterKeys.provider filters/categories -- refactor -- why pass a var in state to a function?
        this.fetchFilters().then(() => {
          this.setState({
            __loadingFilters__: false,
          });

          // Fetch Results
          this.fetchResults().then(() => {
            // The below state change of __loadingApp_ could cause some errs,
            // ... because the promise has not actually executed
            this.setState({
              __loadingApp__: false,
            });
          });
        });
      })
      .catch((err) => {
        console.log("Promise chain failed.");
        this.setState({
          isErrView: { flag: true, err: err },
        });
      });
  }

  // TODO: Run components through profiler, my be rerendering to many times
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props || nextState !== this.state) {
      return true;
    } else {
      return false;
    }
  }

  getUrlParams() {
    const params = [];
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
      params.push({ key: key, value: value });
    });
    return params;
  }

  buildAppFromUrlParams() {
    // User has already been here - no reason to show splash page
    this.setState(
      {
        isSplashView: false, // in prod
        // isSplashView: true // in dev
      },
      () => {
        // Get URL Params -- build data from URL params
        const urlParams = this.getUrlParams();
        if (urlParams.length) {
          urlParams.forEach((p) => {
            if (p.key !== "specialty") {
              if (p.key == "groupNumber") {
                this.setState({ userGroup: p.value });
              }
              this.setState(
                {
                  [p.key]: p.value,
                },
                () => {
                  // TODO: Why pass state when u can just use vars in function ??
                  this.reverseGeoCodeCoords(
                    this.state.lat,
                    this.state.lng,
                    undefined,
                  );
                  this.fetchFilters()
                    .then(() => {
                      this.setState({
                        __loadingFilters__: false,
                      });

                      // Fetch Results
                      this.fetchResults().then(() => {
                        // The below state change of __loadingApp_ could cause some errs,
                        // ... because the promise has not actually executed
                        this.setState({
                          __loadingApp__: false,
                        });
                      });
                    })
                    .catch((err) => {
                      console.log("Promise chain failed.");
                      this.setState({
                        isErrView: { flag: true, err: err },
                      });
                    });
                },
              );
            } else {
              console.log("Specialty: ", p);
              // TODO: having trouble here -- trying to sync specialty ids and convert them to the filter object to construct the filterobj state
              // TODO: will revisit
              // turn specialty ids into arr of filter objs
              // console.log('URL PARAMS FROM BLOCK: ', urlParams, p);
              // let searchType;
              // urlParams.forEach((param) => {
              // 	if (param.key == 'searchType') {
              // 		searchType = param.value;
              // 	}
              // });
              // let specIdArr = p.value.split(',');
              // this.fetchFilters()
              // 	.then(() => {
              // 		this.setState({
              // 			__loadingFilters__: false
              // 		});
              // 		console.log(searchType, specIdArr);
              // 		switch (searchType) {
              // 			case filterKeys.facility:
              // 				specIdArr.forEach((specId) => {
              // 					this.state.facilityFilters.forEach((filt) => {
              // 						console.log(filt, specId);
              // 						if (filt.ehnSpecialtyId == specId) {
              // 							console.log(filt);
              // 						}
              // 					});
              // 				});
              // 				break;
              // 			case filterKeys.provider:
              // 				specIdArr.forEach((specId) => {
              // 					this.state.providerFilters.forEach((filt) => {
              // 						if (filt.ehnSpecialtyId == specId) {
              // 							console.log(filt);
              // 						}
              // 					});
              // 				});
              // 				break;
              // 			case filterKeys.primaryCare:
              // 				specIdArr.forEach((specId) => {
              // 					this.state.primaryCareFilters.forEach((filt) => {
              // 						if (filt.ehnSpecialtyId == specId) {
              // 							console.log(filt);
              // 						}
              // 					});
              // 				});
              // 				break;
              // 			case filterKeys.pediatric:
              // 				specIdArr.forEach((specId) => {
              // 					this.state.pediatricCareFilters.forEach((filt) => {
              // 						console.log(filt, specId);
              // 						if (filt.ehnSpecialtyId == specId) {
              // 							console.log(filt);
              // 						}
              // 					});
              // 				});
              // 				break;
              // 		}
              // 		// Fetch Results
              // 		this.fetchResults().then(() => {
              // 			// The below state change of __loadingApp_ could cause some errs,
              // 			// ... because the promise has not actually executed
              // 			this.setState({
              // 				__loadingApp__: false
              // 			});
              // 		});
              // 	})
              // 	.catch((err) => {
              // 		console.log('Promise chain failed.');
              // 		this.setState({
              // 			isErrView: { flag: true, err: err }
              // 		});
              // 	});
              // if (this.state.facilityFilters.length) {
              // 	console.log(this.state.facilityFilters);
              // } else if (this.state.providerFilters.length) {
              // 	console.log(this.state.providerFilters);
              // } else if (this.state.primaryCareFilters.length) {
              // 	console.log(this.state.primaryCareFilters);
              // } else if (this.state.pediatricCareFilters.length) {
              // 	console.log(this.state.pediatricCareFilters);
              // }
              // this.setState({
              // 	[p.key]:
              // })
            }
          });
        }
      },
    );
  }

  // TODO: consolidate functions into open and close to handle --------------
  openCantFindYourProvider = () => {
    this.setState(
      {
        isCantFindADoctorDialogOpen: true,
      },
      () => {
        // const elements = document.getElementsByClassName('grecaptcha-badge');
        // // $(".grecaptcha-badge").appendTo(".jss11.jss37.jss12.jss50.jss51.jss54")
        // if ($('.grecaptcha-badge') || elements) {
        // 	$('.grecaptcha-badge').removeClass('grecaptcha-badge').addClass('grecaptcha-badge-visible');
        // }
      },
    );
  };
  closeCantFindYourProvider = () => {
    this.setState(
      {
        isCantFindADoctorDialogOpen: false,
      },
      () => {
        // const elements = document.getElementsByClassName('grecaptcha-badge');
        // if ($('.grecaptcha-badge-visible') || elements) {
        // 	$('.grecaptcha-badge-visible').removeClass('grecaptcha-badge-visible').addClass('grecaptcha-badge');
        // }
      },
    );
  };
  // ------------------------------------------------------------------------

  openCantFindYourProviderMember = () => {
    this.setState({
      isCantFindADoctorMemberDialogOpen: true,
    });
  };
  closeCantFindYourProviderMember = () => {
    this.setState({
      isCantFindADoctorMemberDialogOpen: false,
    });
  };

  reverseGeoCodeCoords = (lat, lng, resolve) => {
    const commaChar = "%2C"; // encodeURIComponent a better solution, is encoding for a comma

    return fetch(
      this.googleGeoApiPath +
        `
	latlng=${lat}${commaChar}${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
	`,
      {
        method: "get",
        headers: {
          // Access-Control-Allow-Headers
          // dataType: 'json',
          // 'Content-Type': 'application/json'
          // '
          // Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Credentials': 'true',
          // 'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
          // 'Access-Control-Allow-Headers':
          // 	'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
        },
      },
    )
      .then((response) => {
        if (
          response.status !== 200 &&
          response.status !== 201 &&
          response.status !== 201
        ) {
          console.log(
            "Error: Failed to fetch filters. Status Code: " + response.status,
          );
          this.setState({
            isErrView: { flag: true, response: response },
          });
          return;
        }
        response.json().then((res) => {
          if (res.results.length) {
            const address = res.results[0].formatted_address;
            this.setState({
              lat,
              lng,
              didUserDenyLocationPrivledges: false,
            });

            if (resolve) {
              resolve(address);
            } else {
              this.setState({
                __loadingUserLocation__: false,
                userLocation: address,
              });
            }
          }
        });
      })
      .catch((err) => {
        console.log("Error fetching filters.");
        this.setState({
          isErrView: { flag: true, err: err },
        });
      });
  };

  fetchUserLocation() {
    const _this = this;
    if (navigator.geolocation) {
      return new Promise((resolve) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            _this.reverseGeoCodeCoords(
              position.coords.latitude,
              position.coords.longitude,
              resolve,
            );
          },
          (error) => {
            if (error.code == error.PERMISSION_DENIED) {
              _this.setState({
                didUserDenyLocationPrivledges: true,
              });
            }
          },
        );
      });
    } else {
      console.log("Browser does not support geolocation.");
    }
  }

  fetchFilters() {
    this.setState({
      __loadingFilters__: true,
    });

    let searchTypeStr =
      typeof this.state.searchType == "object"
        ? this.state.searchType[0]
        : this.state.searchType;

    return fetch(
      this.providerApiPath + "/specialties?searchType=" + searchTypeStr,
      {
        method: "get",
        headers: {
          dataType: "json",
          contentType: "application/json",
        },
      },
    )
      .then((response) => {
        if (
          response.status !== 200 &&
          response.status !== 201 &&
          response.status !== 201
        ) {
          console.log(
            "Error: Failed to fetch filters. Status Code: " + response.status,
          );
          this.setState({
            isErrView: { flag: true, response: response },
          });
          return;
        }

        response.json().then((filters) => {
          let filterArrName;

          if (filters && filters[0]) {
            switch (filters[0].specialtyType) {
              case filterKeys.provider:
                filterArrName = "providerFilters";
                break;
              case filterKeys.facility:
                filterArrName = "facilityFilters";
                break;
              case filterKeys.pediatric:
                filterArrName = "pediatricCareFilters";
                break;
              default:
            }

            if (filters[0].isPrimaryCare) {
              filterArrName = "primaryCareFilters";
            }

            if (filters[0].isPediatric) {
              filterArrName = "pediatricCareFilters";
            }
          }

          this.setState({
            [filterArrName]: filters,
          });
        });
      })
      .catch((err) => {
        console.log("Error fetching filters.");
        this.setState({
          isErrView: { flag: true, err: err },
        });
      });
  }

  toggleDrawer = () => {
    if (this.state.isDrawerOpen) {
      this.setState({
        isDrawerOpen: false,
      });
    } else {
      this.setState({
        isDrawerOpen: true,
      });
    }
  };

  cleanFiltersState = (filter) => {
    switch (filter[0]) {
      case filterKeys.provider:
        this.setState({
          facilityFilters: [],
          primaryCareFilters: [],
          pediatricCareFilters: [],
        });
        break;
      case filterKeys.facility:
        this.setState({
          providerFilters: [],
          primaryCareFilters: [],
          pediatricCareFilters: [],
        });
        break;
      case filterKeys.primaryCare:
        this.setState({
          providerFilters: [],
          facilityFilters: [],
          pediatricCareFilters: [],
        });
        break;
      case filterKeys.pediatric:
        this.setState({
          providerFilters: [],
          facilityFilters: [],
          primaryCareFilters: [],
        });
        break;
      default:
    }
  };

  handleFilterToggle = (filter) => {
    let filterObj = Object.assign({}, this.state.filterObj);
    // ADD FILTERS
    if (!filter.specialties) {
      filterObj[filter.ehnSpecialtyId] = true;
      this.handleSetFilterObjToState(filterObj);
      this.handleSelectedFilters({
        type: filterTypes.ADD_TOGGLED_CHILD_FILTER,
        data: filter,
      });
    } else if (filter.specialties && !filter.specialties.length) {
      filterObj[filter.ehnSpecialtyId] = true;
      this.handleSetFilterObjToState(filterObj);
      this.handleSelectedFilters({
        type: filterTypes.ADD_TOGGLED_SINGULAR_FILTER,
        data: filter,
      });
    } else if (filter.specialties && filter.specialties.length) {
      let chipsToAddArr = [];
      chipsToAddArr.push({
        type: filterTypes.ADD_TOGGLED_PARENT_AND_CHILD_FILTERS,
        data: filter,
      });
      filterObj[filter.ehnSpecialtyId] = true;
      filter.specialties.forEach((fs, i) => {
        filterObj[fs.ehnSpecialtyId] = true;
        chipsToAddArr.push({
          type: filterTypes.ADD_TOGGLED_PARENT_AND_CHILD_FILTERS,
          data: fs,
        });
      });
      this.handleSetFilterObjToState(filterObj);
      this.handleSelectedFilters({
        type: filterTypes.ADD_TOGGLED_PARENT_AND_CHILD_FILTERS,
        data: chipsToAddArr,
      });
    }
  };

  fetchResults() {
    const _this = this;

    // TODO: do function by itself, do not run everytime, isolate....
    const getCoordsFromAddress = () => {
      return new Promise((resolve, reject) => {
        fetch(
          this.googleGeoApiPath +
            `
				address=${this.state.userLocation}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
				`,
          {
            method: "get",
            headers: {
              // Access-Control-Allow-Headers
              // dataType: 'json',
              // 'Content-Type': 'application/json'
              // '
              // Access-Control-Allow-Origin': '*',
              // 'Access-Control-Allow-Credentials': 'true',
              // 'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
              // 'Access-Control-Allow-Headers':
              // 	'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
            },
          },
        )
          .then((response) => {
            if (
              response.status !== 200 &&
              response.status !== 201 &&
              response.status !== 201
            ) {
              console.log(
                "Error: Failed to fetch filters. Status Code: " +
                  response.status,
              );
              this.setState({
                isErrView: { flag: true, response: response },
              });
              return;
            }
            response.json().then((res) => {
              if (res.results.length) {
                resolve(res.results[0].geometry.location);
              } else {
                // set to initial state
                this.setState({
                  searchResults: [],
                  fuzzyCount: 0,
                  recordCount: 0,
                  pageCount: 0,
                  __loadingSearchResults__: false,
                  __loadingApp__: false,
                });
                resolve();
              }
            });
          })
          .catch((err) => {
            console.log("Error fetching filters.");
            this.setState({
              isErrView: { flag: true, err: err },
            });
          });
      });
    };

    const fetchProviders = () => {
      const deleteCommasFromFilterStr = (str) => {
        if (str.includes(",")) {
          return str.replace(",", "");
        }
        return str;
      };

      const queryParams = {
        filterString: deleteCommasFromFilterStr(_this.state.filterString),
        lat: _this.state.lat,
        lng: _this.state.lng,
        distance: _this.state.distance,
        page: _this.state.page,
        fiscalYear: _this.state.fiscalYear,
        network: _this.state.network,
        searchType: _this.state.searchType,
        size: _this.state.size, // pagination,
        groupNumber: _this.state.userGroup,
        sort: this.state.sort,
      };

      if (
        _this.state.specialty.length &&
        typeof _this.state.specialty !== "string"
      ) {
        let specialtyFilters = [];
        _this.state.specialty.forEach((filter) => {
          specialtyFilters.push(filter.data.ehnSpecialtyId);
        });

        queryParams["specialty"] = specialtyFilters;
      }

      const params = buildQueryParams(queryParams);
      this.props.history.push({
        search: params,
      });

      return fetch(_this.providerApiPath + "/providers" + params, {
        method: "get",
        headers: {
          dataType: "json",
          contentType: "application/json",
        },
      })
        .then((response) => {
          if (response.status !== 200 && response.status !== 201) {
            this.setState({
              isErrView: { flag: true, response: response },
            });
            return;
          }

          let recordCount, fuzzyCount;
          response.headers.forEach(function (val, key) {
            if (key === "x-record-count") {
              recordCount = val;
            }

            if (key === "x-fuzzy-result-count") {
              fuzzyCount = val;
            }
          });

          let pageCount =
            Math.floor(recordCount / _this.state.size) +
            (recordCount % _this.state.size > 0 ? 1 : 0);

          response.json().then((searchResults) => {
            this.setState({
              searchResults,
              fuzzyCount,
              recordCount,
              pageCount,
              __loadingSearchResults__: false,
            });
          });
        })
        .catch((err) => {
          console.log("Error fetching providers: ");
          _this.setState({
            isErrView: { flag: true, err: err },
          });
        });
    };

    // TODO: Will get refactored
    // return root promise
    if (this.state.userLocation) {
      return getCoordsFromAddress()
        .then((coords) => {
          if (coords) {
            this.setState(
              {
                lat: coords.lat,
                lng: coords.lng,
              },
              () => {
                // TODO: Refactor nested promise??
                fetchProviders();
              },
            );
          }
        })
        .catch((err) => {
          console.log("Error fetching coords from google.");
          _this.setState({
            isErrView: { flag: true, err: err },
          });
        });
    } else {
      this.setState({
        searchResults: [],
        fuzzyCount: 0,
        recordCount: 0,
        pageCount: 0,
        __loadingSearchResults__: false,
        __loadingApp__: false,
      });
      return new Promise((resolve) => {
        resolve();
      });
    }
  }

  handleSelectedFilters = (filter) => {
    this.setState(
      {
        __loadingFilters__: true,
      },
      () => {
        let filterObj = Object.assign({}, this.state.filterObj);
        let specialty, filterArr;
        const _this = this;

        const doSetState = (specialty, filterObj) => {
          _this.setState(
            {
              specialty,
              filterObj,
            },
            () => {
              _this.setState({
                __loadingFilters__: false,
              });
              _this.doSearch(_this.DONT_HIDE_SEARCH);
            },
          );
        };

        switch (filter.type) {
          case filterTypes.ADD_TOGGLED_SINGULAR_FILTER:
            filterArr = [filter];
            specialty = this.state.specialty.concat(filterArr);
            filterObj[filter.data.ehnSpecialtyId] = true;
            doSetState(specialty, filterObj);
            break;
          case filterTypes.ADD_TOGGLED_PARENT_AND_CHILD_FILTERS:
            specialty = this.state.specialty.concat(filter.data);
            filterObj[filter.data.ehnSpecialtyId] = true;
            filter.data.forEach((f, i) => {
              filterObj[f.data.ehnSpecialtyId] = true;
            });
            doSetState(specialty, filterObj);
            break;
          case filterTypes.ADD_TOGGLED_CHILD_FILTER:
            filterArr = [filter];
            specialty = this.state.specialty.concat(filterArr);
            filterObj[filter.data.ehnSpecialtyId] = true;
            doSetState(specialty, filterObj);
            break;
          case filterTypes.DELETE_TOGGLED_SINGULAR_FILTER:
            specialty = this.state.specialty.filter((f) => {
              return f.data.ehnSpecialtyId == filter.data.ehnSpecialtyId
                ? false
                : true;
            });
            filterObj[filter.data.ehnSpecialtyId] = false;
            doSetState(specialty, filterObj);
            break;
          case filterTypes.DELETE_CHIP_SINGULAR_FILTER:
            specialty = this.state.specialty.filter((f) => {
              return f.data.ehnSpecialtyId == filter.data.ehnSpecialtyId
                ? false
                : true;
            });
            filterObj[filter.data.ehnSpecialtyId] = false;
            doSetState(specialty, filterObj);
            break;
          case filterTypes.DELETE_TOGGLED_PARENT_AND_CHILD_FILTERS:
            // Clear filters from filterObj
            filterObj[filter.data.ehnSpecialtyId] = false;
            filter.data.forEach((fs, i) => {
              filterObj[fs.data.ehnSpecialtyId] = false;
            });

            // Clear child filters from arr
            specialty = this.state.specialty.filter((f) => {
              return filter.data &&
                filter.data[0].data.specialties &&
                filter.data[0].data.specialties.length &&
                filter.data[0].data.specialties.indexOf(f.data) > -1
                ? false
                : true;
            });

            // Clear parent filter from arr
            specialty = specialty.filter((f) => {
              return filter.data[0].data !== f.data ? true : false;
            });

            doSetState(specialty, filterObj);
            break;
          case filterTypes.DELETE_TOGGLED_CHILD_FILTER:
            specialty = this.state.specialty.filter((f) => {
              return f.data.ehnSpecialtyIdh == filter.data.ehnSpecialtyId
                ? false
                : true;
            });
            filterObj[filter.data.ehnSpecialtyId] = false;
            doSetState(specialty, filterObj);
            break;
          default:
        }
      },
    );
  };

  setDeleteFilterType = (filter) => {
    filter.type = "DELETE_CHIP_SINGULAR_FILTER";
    this.handleSelectedFilters(filter);
  };

  handleSetFilterObjToState = (filterObj) => {
    this.setState({
      filterObj,
    });
  };

  handleSearchTextChange = (event) => {
    this.setState({
      filterString: event.target.value,
    });
  };

  handleLocationChange = (event) => {
    this.setState({
      userLocation: event.target.value,
    });
  };

  handleGroupChange = (event) => {
    this.setState({
      userGroup: event.target.value,
    });
  };

  doSearch = (hideSearch) => {
    if (
      this.state.userGroup &&
      this.state.userGroup == "1008162" &&
      !this.alertPresent162
    ) {
      this.alertPresent162 = true;
      alert(
        "Note that access to the Memorial Hermann network is powered by EHN. You will see the EHN logo on the top left corner of this provider search.",
      );
    } else if (
      this.state.userGroup &&
      this.state.userGroup == "1008164" &&
      !this.alertPresent164
    ) {
      this.alertPresent164 = true;
      alert(
        "Note that access to the Memorial Hermann network is powered by EHN. You will see the EHN logo on the top left corner of this provider search.",
      );
    } else if (
      this.state.userGroup &&
      this.state.userGroup == "1008163" &&
      !this.alertPresent163
    ) {
      this.alertPresent163 = true;
      alert(
        "Note that access to the Baptist network is powered by EHN. You will see the EHN logo on the top left corner of this provider search.",
      );
    } else if (
      this.state.userGroup &&
      this.state.userGroup == "1008117" &&
      !this.alertPresent117
    ) {
      this.alertPresent117 = true;
      alert(
        "Note that access to the Baptist network is powered by EHN. You will see the EHN logo on the top left corner of this provider search.",
      );
    }
    // Handle mobile view, and dont enter computer results view
    if (this.state.isMobileView) {
      // 1100 is the first breakpoint in the css mediaqueries
      this.setState(
        {
          __loadingSearchResults__: true,
          searchResults: [],
          recordCount: 0,
          fuzzyCount: 0,
          pageCount: 0,
          page: 1,
          focusedOnMarker: undefined,
        },
        () => {
          this.fetchResults()
            .then(() => {
              if (this.state.isMobileSearch) {
                this.setState({
                  isDrawerOpen: false,
                  isMobileSearch: false,
                });
                if (this.state.filterString) {
                  this.setState({
                    showFilterStringLabel: true,
                  });
                }
              }
            })
            .catch((err) => {
              this.setState({
                isErrView: { flag: true, err: err },
              });
            });
        },
      );
    } else {
      this.setState(
        {
          __loadingSearchResults__: true,
          searchResults: [],
          recordCount: 0,
          fuzzyCount: 0,
          pageCount: 0,
          page: 1,
          focusedOnMarker: undefined,
        },
        () => {
          this.fetchResults()
            .then(() => {
              if (!this.state.__loadingApp__ && hideSearch) {
                this.setState({
                  sectionsContainerOnTop: true,
                });
                if (this.state.filterString) {
                  this.setState({
                    showFilterStringLabel: true,
                  });
                }
                if (this.state.userLocation) {
                  this.setState({
                    showResultsLocationMsg: true,
                  });
                }
              }
            })
            .catch((err) => {
              this.setState({
                isErrView: { flag: true, err: err },
              });
            });
        },
      );
    }
  };

  splashSelectsFilter = (filter) => {
    this.handleSearchTypeChange(filter);

    this.setState({
      isSplashView: false,
    });
  };

  handleSearchTypeChange = (event) => {
    let searchType;
    if (event && event.target && event.target.value) {
      searchType = [event.target.value];
    } else {
      searchType = [event];
    }

    this.cleanFiltersState(searchType);

    this.setState(
      {
        searchType,
        __loadingSearchResults__: true,
        specialty: [],
        filterObj: {},
      },
      () => {
        this.fetchFilters()
          .then(() => {
            this.setState(
              {
                __loadingFilters__: false,
              },
              () => {
                this.doSearch(this.DONT_HIDE_SEARCH);
                this.setState({
                  focusedOnMarker: undefined,
                });
              },
            );
          })
          .catch((err) => {
            console.log("Error fetching filters.");
            this.setState({
              isErrView: { flag: true, err: err },
            });
          });
      },
    );
  };

  handlePaginationChange = (e) => {
    let page;
    const ariaLabelNextItemStr = "nextItem";
    const ariaLabelPrevItemStr = "prevItem";

    if (e.target.type == ariaLabelNextItemStr) {
      page = parseInt(this.state.page) + 1;
    } else if (e.target.type == ariaLabelPrevItemStr) {
      page = parseInt(this.state.page) - 1;
    } else {
      page = parseInt(e.target.innerHTML);
    }

    this.setState(
      {
        focusedOnMarker: undefined,
        __loadingSearchResults__: true,
        page: page,
      },
      () => {
        this.fetchResults()
          .then(() => {
            // do stuff
          })
          .catch((err) => {
            this.setState({
              isErrView: { flag: true, err: err },
            });
          });
      },
    );
  };

  handleEnterKeyPress = (e) => {
    if (e.key == "Enter" || e.keyCode == 13) {
      console.log("HERE");
      e.preventDefault();
      this.doSearch(this.DONT_HIDE_SEARCH);
    }
  };

  handleResultCardClick = (provider) => {
    if (!this.state.isMobileView) {
      this.setState({
        focusedOnMarker: {
          lat: provider.locLatitude,
          lng: provider.locLongitude,
          id: provider.id,
        },
        showMobileMapView: false,
      });
    } else {
      this.setState({
        focusedOnMarker: {
          lat: provider.locLatitude,
          lng: provider.locLongitude,
          id: provider.id,
        },
        showMobileMapView: true,
      });
    }
  };

  clearFilters = () => {
    let filterObj = Object.assign({}, this.state.filterObj);

    if (this.state.providerFilters.length) {
      this.state.providerFilters.forEach((filter) => {
        filterObj[filter.ehnSpecialtyId] = false;
        filter.specialties &&
          filter.specialties.length &&
          filter.specialties.forEach((fs, i) => {
            filterObj[fs.ehnSpecialtyId] = false;
          });
      });
    }

    if (this.state.facilityFilters.length) {
      this.state.facilityFilters.forEach((filter) => {
        filterObj[filter.ehnSpecialtyId] = false;
        filter.specialties &&
          filter.specialties.length &&
          filter.specialties.forEach((fs, i) => {
            filterObj[fs.ehnSpecialtyId] = false;
          });
      });
    }

    if (this.state.primaryCareFilters.length) {
      this.state.primaryCareFilters.forEach((filter) => {
        filterObj[filter.ehnSpecialtyId] = false;
        filter.specialties &&
          filter.specialties.length &&
          filter.specialties.forEach((fs, i) => {
            filterObj[fs.ehnSpecialtyId] = false;
          });
      });
    }

    if (this.state.pediatricCareFilters.length) {
      this.state.pediatricCareFilters.forEach((filter) => {
        filterObj[filter.ehnSpecialtyId] = false;
        filter.specialties &&
          filter.specialties.length &&
          filter.specialties.forEach((fs, i) => {
            filterObj[fs.ehnSpecialtyId] = false;
          });
      });
    }

    this.setState(
      {
        specialty: [],
        filterObj,
      },
      () => {
        this.doSearch(this.DONT_HIDE_SEARCH);
        this.clearFilterString();
      },
    );
  };

  handleLocationIconClick = () => {
    if (this.state.didUserDenyLocationPrivledges) {
      alert(
        "Please enable location tracking services.  Navigate to your browser settings and allow this site to access your location.",
      );
    }
    this.setState(
      {
        focusedOnMarker: undefined,
        __loadingUserLocation__: true,
      },
      () => {
        this.fetchUserLocation()
          .then((userLocation) => {
            this.setState(
              {
                __loadingUserLocation__: false,
                userLocation,
              },
              () => {
                this.doSearch(this.DONT_HIDE_SEARCH);
              },
            );
          })
          .catch((err) => {
            console.log("Failed to fetch user location.");
            this.setState({
              isErrView: { flag: true, err: err },
            });
          });
      },
    );
  };

  handleFixedSearchBtn = () => {
    this.state.sectionsContainerOnTop
      ? this.setState({ sectionsContainerOnTop: false }, () => {
          window.scrollTo(0, 0);
        })
      : this.setState({ sectionsContainerOnTop: true }, () => {
          window.scrollTo(0, 0);
        });
  };

  handleRangeSelect = (e) => {
    this.setState(
      { distance: e.target.value, focusedOnMarker: undefined },
      () => {
        if (this.state.userGroup != undefined && this.state.userGroup !== "")
          this.doSearch(this.DONT_HIDE_SEARCH);
        else this.setState({ groupNumberError: true });
      },
    );
  };

  handleSortChange = (e) => {
    e.preventDefault();
    this.setState({ sort: e.target.value, focusedOnMarker: undefined }, () => {
      if (this.state.userGroup != undefined && this.state.userGroup !== "")
        this.doSearch(this.DONT_HIDE_SEARCH);
      else this.setState({ groupNumberError: true });
    });
  };

  // Deletes ', USA' from text
  renderLocationText = (str) => {
    if (str.indexOf(", USA") > -1) {
      str = str.substring(0, str.length - 5);
      return str;
    }
    return str;
  };

  handleMobileSearchBtn = () => {
    this.setState({ isMobileSearch: true }, () => {
      this.doSearch(this.DONT_HIDE_SEARCH);
    });
  };

  clearFilterString = () => {
    this.setState(
      {
        filterString: "",
        showFilterStringLabel: false,
      },
      () => {
        this.doSearch(this.DONT_HIDE_SEARCH);
      },
    );
  };

  toggleMapView = () => {
    if (!this.state.showMobileMapView) {
      this.setState({
        showMobileMapView: true,
      });
    } else {
      this.setState({
        showMobileMapView: false,
        focusedOnMarker: undefined,
      });
    }
  };

  render() {
    const windowHeight = window.innerHeight;

    const fixedSearchBtn = this.state.sectionsContainerOnTop ? (
      <div>
        <Tooltip title="Go to Search">
          <Chip
            label="SEARCH"
            icon={<SearchIcon style={{ color: "white" }} />}
            onClick={this.handleFixedSearchBtn}
            style={{
              backgroundColor: "#216193",
              color: "white",
              fontSize: 16,
              boxShadow: "0px 5px 20px -5px black",
              position: "fixed",
              top: -10,
              zIndex: 10,
              width: 200,
              left: "45%",
              height: 50,
              paddingTop: 10,
            }}
          />
        </Tooltip>
      </div>
    ) : (
      <div />
    );

    const appView = this.state.__loadingApp__ ? (
      <div className="home-loader-container">
        <div className="home-loader-text">Loading Provider Search</div>
        <CircularProgress style={{ color: "grey" }} thickness={4} size={50} />
      </div>
    ) : (
      <div id="app-container">
        <FindADoctorDialog
          isOpen={this.state.isCantFindADoctorDialogOpen}
          closeDialog={this.closeCantFindYourProvider}
        />
        <FindADoctorMemberDialog
          isOpen={this.state.isCantFindADoctorMemberDialogOpen}
          closeDialog={this.closeCantFindYourProviderMember}
        />
        {/* Data Issue Feedback Form Dialog */}
        <DialogFormWrapper
          // Dialog Specific Props
          dialogOpen={this.state.dataIssueDialogOpen}
          dialogTitle={`Are our records incorrect?`}
          formTitle={`Please submit your corrections in this form so that we can make the necessary changes.`}
          // Shared props
          formMode={FormMode.Submit}
          // Form Specific Props
          formComponent={DataIssueFeedbackForm}
          onDialogClose={this.handleDataIssueFeedbackFormClose}
          selectedProvider={this.state.selectedProvider}
        />

        <MobileView
          isDrawerOpen={this.state.isDrawerOpen}
          toggleDrawer={this.toggleDrawer}
          classes={this.props.classes}
          handleMobileSearchBtn={this.handleMobileSearchBtn}
          handleEnterKeyPress={this.handleEnterKeyPress}
          filterString={this.state.filterString}
          userLocation={this.state.userLocation}
          userGroup={this.state.userGroup}
          userGroupErr={this.state.userGroupErr}
          handleGroupChange={(e) => this.handleGroupChange(e)}
          handleSearchTextChange={(e) => this.handleSearchTextChange(e)}
          handleLocationChange={(e) => this.handleLocationChange(e)}
          handleRangeSelect={this.handleRangeSelect}
          sort={this.state.sort}
          distance={this.state.distance}
          handleLocationIconClick={this.handleLocationIconClick}
          searchType={this.state.searchType}
          handleSearchTypeChange={this.handleSearchTypeChange}
          providerFilters={this.state.providerFilters}
          facilityFilters={this.state.facilityFilters}
          primaryCareFilters={this.state.primaryCareFilters}
          pediatricCareFilters={this.state.pediatricCareFilters}
          handleSelectedFilters={this.handleSelectedFilters}
          handleSetFilterObjToState={this.handleSetFilterObjToState}
          filterObj={this.state.filterObj}
          windowHeight={windowHeight}
          showMobileMapView={this.state.showMobileMapView}
          __loadingFilters__={this.state.__loadingFilters__}
        />
        <HeaderSection
          goToSplashPage={() => this.setState({ isSplashView: true })}
          handleCantFindYourProvider={this.openCantFindYourProvider}
          handleCantFindYourProviderMember={this.openCantFindYourProviderMember}
          sectionsContainerOnTop={this.state.sectionsContainerOnTop}
          handleSearchTextChange={(e) => this.handleSearchTextChange(e)}
          filterString={this.state.filterString}
          userGroup={this.state.userGroup}
          userGroupErr={this.state.userGroupErr}
          handleGroupChange={(e) => this.handleGroupChange(e)}
          handleLocationChange={(e) => this.handleLocationChange(e)}
          userLocation={this.renderLocationText(this.state.userLocation)}
          handleLocationIconClick={this.handleLocationIconClick}
          handleRangeSelect={this.handleRangeSelect}
          distance={this.state.distance}
          sort={this.state.sort}
          doSearch={() => this.doSearch(this.DONT_HIDE_SEARCH)}
          handleSearchTypeChange={this.handleSearchTypeChange}
          searchType={this.state.searchType}
        />
        <div
          className={
            this.state.sectionsContainerOnTop
              ? "sections-container-on-top"
              : "sections-container"
          }>
          {fixedSearchBtn}
          <div className="sections">
            <div className="filters-section">
              <FilterSection
                providerFilters={this.state.providerFilters}
                facilityFilters={this.state.facilityFilters}
                primaryCareFilters={this.state.primaryCareFilters}
                pediatricCareFilters={this.state.pediatricCareFilters}
                handleSelectedFilters={this.handleSelectedFilters}
                setFilterObjToState={this.handleSetFilterObjToState}
                filterObj={this.state.filterObj}
                windowHeight={windowHeight}
                __loadingFilters__={this.state.__loadingFilters__}
              />
            </div>
            {this.state.showMobileMapView ? (
              <div
                className="results-map-section"
                style={{ display: "block", width: "100%" }}>
                <MenuButton
                  label="Can't find your provider?"
                  options={[
                    {
                      label: "Member",
                      onClick: this.openCantFindYourProviderMember,
                    },
                    {
                      label: "Provider",
                      onClick: this.openCantFindYourProvider,
                    },
                  ]}
                  buttonStyle={{
                    color: "#216193",
                    fontSize: 12,
                    width: "90%",
                    marginLeft: "5%",
                    marginBottom: 20,
                  }}
                  popperStyle={{ width: "88%" }}
                />
                <Button
                  onClick={this.toggleMapView}
                  variant="outlined"
                  style={{ width: "90%", marginLeft: "5%", marginBottom: 20 }}>
                  Back to List View
                </Button>
                {this.state.searchResults.length ? (
                  <ResultsMapSection
                    searchResults={this.state.searchResults}
                    page={this.state.page}
                    lat={this.state.lat}
                    lng={this.state.lng}
                    windowHeight={windowHeight}
                    focusedOnMarker={this.state.focusedOnMarker}
                    searchHidden={this.state.sectionsContainerOnTop}
                    isDrawerOpen={this.state.isDrawerOpen}
                  />
                ) : this.state.userLocation &&
                  !this.state.__loadingSearchResults__ ? (
                  <ResultsMapSection
                    searchResults={this.state.searchResults}
                    page={this.state.page}
                    lat={this.state.lat}
                    lng={this.state.lng}
                    windowHeight={windowHeight}
                    focusedOnMarker={this.state.focusedOnMarker}
                    searchHidden={this.state.sectionsContainerOnTop}
                    isDrawerOpen={this.state.isDrawerOpen}
                  />
                ) : (
                  <ResultsMapSection
                    searchResults={this.state.searchResults}
                    page={this.state.page}
                    lat={this.state.lat}
                    lng={this.state.lng}
                    windowHeight={windowHeight}
                    focusedOnMarker={this.state.focusedOnMarker}
                    searchHidden={this.state.sectionsContainerOnTop}
                    isDrawerOpen={this.state.isDrawerOpen}
                  />
                )}
              </div>
            ) : (
              <div className="results-section">
                {this.state.isMobileView ? (
                  <div>
                    <MenuButton
                      label="Can't find your provider?"
                      options={[
                        {
                          label: "Member",
                          onClick: this.openCantFindYourProviderMember,
                        },
                        {
                          label: "Provider",
                          onClick: this.openCantFindYourProvider,
                        },
                      ]}
                      buttonStyle={{
                        color: "#216193",
                        fontSize: 12,
                        width: "90%",
                        marginLeft: "5%",
                        marginBottom: 20,
                      }}
                      popperStyle={{ width: "88%" }}
                    />
                    <Button
                      onClick={this.toggleMapView}
                      variant="outlined"
                      style={{
                        width: "90%",
                        marginLeft: "5%",
                        marginBottom: 20,
                      }}>
                      Go to Map View
                    </Button>
                  </div>
                ) : null}
                <ResultsListSection
                  clearFilterString={() => this.clearFilterString()}
                  showFilterStringLabel={this.state.showFilterStringLabel}
                  showResultsLocationMsg={this.state.showResultsLocationMsg}
                  sort={this.state.sort}
                  recordCount={this.state.recordCount}
                  searchResults={this.state.searchResults}
                  page={this.state.page}
                  userLocation={this.state.userLocation}
                  specialty={this.state.specialty}
                  pageCount={this.state.pageCount}
                  clearFilters={this.clearFilters}
                  searchHidden={this.state.sectionsContainerOnTop}
                  windowHeight={windowHeight}
                  handlePaginationChange={(e) => this.handlePaginationChange(e)}
                  handleSortChange={(e) => this.handleSortChange(e)}
                  setDeleteFilterType={(filter) =>
                    this.setDeleteFilterType(filter)
                  }
                  handleResultCardClick={(provider) =>
                    this.handleResultCardClick(provider)
                  }
                  filterString={this.state.filterString}
                  __loadingSearchResults__={this.state.__loadingSearchResults__}
                  searchType={this.state.searchType}
                  onDataIssueButtonClick={this.handleDataIssueButtonClick}
                />
              </div>
            )}
            <div className="results-map-section">
              {this.state.searchResults.length ? (
                <ResultsMapSection
                  searchResults={this.state.searchResults}
                  page={this.state.page}
                  lat={this.state.lat}
                  lng={this.state.lng}
                  windowHeight={windowHeight}
                  focusedOnMarker={this.state.focusedOnMarker}
                  searchHidden={this.state.sectionsContainerOnTop}
                  isDrawerOpen={this.state.isDrawerOpen}
                />
              ) : this.state.userLocation &&
                !this.state.__loadingSearchResults__ ? (
                <ResultsMapSection
                  searchResults={this.state.searchResults}
                  page={this.state.page}
                  lat={this.state.lat}
                  lng={this.state.lng}
                  windowHeight={windowHeight}
                  focusedOnMarker={this.state.focusedOnMarker}
                  searchHidden={this.state.sectionsContainerOnTop}
                  isDrawerOpen={this.state.isDrawerOpen}
                />
              ) : (
                <ResultsMapSection
                  searchResults={this.state.searchResults}
                  page={this.state.page}
                  lat={this.state.lat}
                  lng={this.state.lng}
                  windowHeight={windowHeight}
                  focusedOnMarker={this.state.focusedOnMarker}
                  searchHidden={this.state.sectionsContainerOnTop}
                  isDrawerOpen={this.state.isDrawerOpen}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );

    let view = <div />;

    if (this.state.isErrView) {
      view = (
        <div style={{ height: window.innerHeight }}>
          <ErrorView err={this.state.isErrView} />;
        </div>
      );
    } else if (this.state.isSplashView) {
      view = (
        <div style={{ height: window.innerHeight }}>
          <SplashView
            splashSelectsFilter={(filter) => this.splashSelectsFilter(filter)}
            isMobileView={this.state.isMobileView}
          />
        </div>
      );
    } else {
      view = appView;
    }

    return view;
  }
}

export default withStyles(styles)(AppView);
