import React, { Component } from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";

export default class NotFoundView extends Component {
  componentDidMount() {
    // Scroll to top of the page
    window.scroll(0, 0);
  }

  render() {
    return (
      <Card
        style={{
          marginTop: 100,
          marginLeft: "10%",
          width: "80%",
          height: 300,
        }}>
        <CardContent>
          <h2>404 - Page Not Found</h2>
          <hr />
          <br />
          <Button
            variant="contained"
            style={{
              height: "100%",
              minHeight: 50,
              fontSize: 14,
              marginTop: 30,
              backgroundColor: "#216193",
              color: "white",
              minWidth: 140,
              width: "50%",
              marginLeft: "25%",
            }}
            onClick={() => this.props.history.push("/")}>
            Go To Provider Search
          </Button>
        </CardContent>
      </Card>
    );
  }
}
