import React, { Component } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import { filterTypes } from "../../../util/constants";

export default class FilterSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filtersDropDownObj: undefined,
      filterCategoryTitle: undefined,
    };
  }

  componentDidMount() {
    this.buildCheckBoxObjState();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props || nextState !== this.state) {
      return true;
    } else {
      return false;
    }
  }

  handleFilterToggle = (filter) => {
    let filterObj = Object.assign({}, this.props.filterObj);
    if (!this.props.filterObj[filter.ehnSpecialtyId]) {
      // ADD FILTERS
      if (!filter.specialties) {
        filterObj[filter.ehnSpecialtyId] = true;
        this.props.setFilterObjToState(filterObj);
        this.props.handleSelectedFilters({
          type: filterTypes.ADD_TOGGLED_CHILD_FILTER,
          data: filter,
        });
      } else if (filter.specialties && !filter.specialties.length) {
        filterObj[filter.ehnSpecialtyId] = true;
        this.props.setFilterObjToState(filterObj);
        this.props.handleSelectedFilters({
          type: filterTypes.ADD_TOGGLED_SINGULAR_FILTER,
          data: filter,
        });
      } else if (filter.specialties && filter.specialties.length) {
        let chipsToAddArr = [];
        chipsToAddArr.push({
          type: filterTypes.ADD_TOGGLED_PARENT_AND_CHILD_FILTERS,
          data: filter,
        });
        filterObj[filter.ehnSpecialtyId] = true;
        filter.specialties.forEach((fs, i) => {
          filterObj[fs.ehnSpecialtyId] = true;
          chipsToAddArr.push({
            type: filterTypes.ADD_TOGGLED_PARENT_AND_CHILD_FILTERS,
            data: fs,
          });
        });
        this.props.setFilterObjToState(filterObj);
        this.props.handleSelectedFilters({
          type: filterTypes.ADD_TOGGLED_PARENT_AND_CHILD_FILTERS,
          data: chipsToAddArr,
        });
      }
    } else {
      // DELETE FILTERS
      if (!filter.specialties) {
        filterObj[filter.ehnSpecialtyId] = false;
        this.props.setFilterObjToState(filterObj);
        this.props.handleSelectedFilters({
          type: filterTypes.DELETE_TOGGLED_CHILD_FILTER,
          data: filter,
        });
      } else if (filter.specialties && !filter.specialties.length) {
        filterObj[filter.ehnSpecialtyId] = false;
        this.props.setFilterObjToState(filterObj);
        this.props.handleSelectedFilters({
          type: filterTypes.DELETE_TOGGLED_SINGULAR_FILTER,
          data: filter,
        });
      } else if (filter.specialties && filter.specialties.length) {
        let chipsToDeleteArr = [];
        chipsToDeleteArr.push({
          type: filterTypes.DELETE_TOGGLED_PARENT_AND_CHILD_FILTERS,
          data: filter,
        });
        filterObj[filter.ehnSpecialtyId] = false;
        filter.specialties.forEach((fs, i) => {
          filterObj[fs.ehnSpecialtyId] = false;
          chipsToDeleteArr.push({
            type: filterTypes.DELETE_TOGGLED_PARENT_AND_CHILD_FILTERS,
            data: fs,
          });
        });
        this.props.setFilterObjToState(filterObj);
        this.props.handleSelectedFilters({
          type: filterTypes.DELETE_TOGGLED_PARENT_AND_CHILD_FILTERS,
          data: chipsToDeleteArr,
        });
      }
    }
  };

  buildCheckBoxObjState = () => {
    let filterObj = Object.assign({}, this.props.filterObj);
    let filtersDropDownObj = {};

    if (this.props.providerFilters.length) {
      this.props.providerFilters.forEach((item) => {
        filterObj[item.ehnSpecialtyId] = false;

        // Create the drop down state to keep track of which drop downs are open
        if (item.specialties && item.specialties.length) {
          filtersDropDownObj[item.ehnSpecialtyId] = false;
        }
      });
    }

    if (this.props.facilityFilters.length) {
      this.props.facilityFilters.forEach((item) => {
        filterObj[item.ehnSpecialtyId] = false;
      });
    }

    if (this.props.primaryCareFilters.length) {
      this.props.primaryCareFilters.forEach((item) => {
        filterObj[item.ehnSpecialtyId] = false;
      });
    }

    if (this.props.pediatricCareFilters.length) {
      this.props.pediatricCareFilters.forEach((item) => {
        filterObj[item.ehnSpecialtyId] = false;

        // Create the drop down state to keep track of which drop downs are open
        if (item.specialties && item.specialties.length) {
          filtersDropDownObj[item.ehnSpecialtyId] = false;
        }
      });
    }

    this.setState({
      filtersDropDownObj,
    });
    this.props.setFilterObjToState(filterObj);
  };

  handleDropDownToggle = (filter, type) => {
    let filtersDropDownObj = Object.assign({}, this.state.filtersDropDownObj);

    if (!this.state.filtersDropDownObj[filter.ehnSpecialtyId]) {
      filtersDropDownObj[filter.ehnSpecialtyId] = true;
    } else {
      filtersDropDownObj[filter.ehnSpecialtyId] = false;
    }

    this.setState({
      filtersDropDownObj,
    });

    if (type !== "OPEN_DROPDOWN") {
      this.handleFilterToggle(filter);
    }
  };

  renderFilterCategoryList = () => {
    if (this.props.providerFilters.length) {
      return this.props.providerFilters.map((filter, i) => {
        // Temp fix to hide Pediatric Specialties from Specialty Care category
        if (filter.specialties.length && filter.ehnSpecialtyId !== 210) {
          let type = "OPEN_DROPDOWN";
          return (
            <div
              className="filter-with-children-container"
              style={
                this.state.filtersDropDownObj &&
                this.state.filtersDropDownObj[filter.ehnSpecialtyId]
                  ? { height: "100%" }
                  : { height: 40 }
              }>
              {this.state.filtersDropDownObj &&
              this.state.filtersDropDownObj[filter.ehnSpecialtyId] ? (
                <i
                  className="fas fa-caret-down"
                  style={{ width: 10, marginRight: 10, cursor: "pointer" }}
                  onClick={() => this.handleDropDownToggle(filter, type)}
                />
              ) : (
                <i
                  className="fas fa-caret-right"
                  style={{ width: 10, marginRight: 10, cursor: "pointer" }}
                  onClick={() => this.handleDropDownToggle(filter, type)}
                />
              )}
              <Checkbox
                style={{
                  display: "inline-block",
                  width: "20%",
                  marginLeft: -10,
                }}
                checked={this.props.filterObj[filter.ehnSpecialtyId]}
                onChange={() => this.handleDropDownToggle(filter)}
                value={filter.ehnSpecialtyId}
                color="primary"
              />
              <div className="filter-name-text">{filter.ehnSpecialtyName}</div>
              {this.state.filtersDropDownObj &&
              this.state.filtersDropDownObj[filter.ehnSpecialtyId]
                ? filter.specialties.map((spec, i) => {
                    return (
                      <div style={{ height: "100%", paddingLeft: 60 }}>
                        <Checkbox
                          style={{
                            display: "inline-block",
                            width: "10%",
                          }}
                          checked={this.props.filterObj[spec.ehnSpecialtyId]}
                          onChange={() => this.handleFilterToggle(spec)}
                          value={spec.ehnSpecialtyId}
                          color="primary"
                        />
                        <div className="filter-name-text">
                          {spec.ehnSpecialtyName}
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          );
        } else {
          // Temp fix to hide Pediatric Specialties from Specialty Care category
          if (filter.ehnSpecialtyId !== 210) {
            return (
              <div className="filter-container">
                <Checkbox
                  style={{
                    display: "inline-block",
                    width: "20%",
                    marginLeft: 10,
                  }}
                  checked={this.props.filterObj[filter.ehnSpecialtyId]}
                  onChange={() => this.handleFilterToggle(filter)}
                  value={filter.ehnSpecialtyId}
                  color="primary"
                />
                <div className="filter-name-text">
                  {filter.ehnSpecialtyName}
                </div>
              </div>
            );
          }
          return null;
        }
      });
    }

    if (this.props.facilityFilters.length) {
      return this.props.facilityFilters.map((filter, i) => {
        return (
          <div className="facility-filter-container">
            <Checkbox
              style={{
                display: "inline-block",
                width: "20%",
                marginLeft: "0",
              }}
              checked={this.props.filterObj[filter.ehnSpecialtyId]}
              onChange={() => this.handleFilterToggle(filter)}
              value={filter.ehnSpecialtyId}
              color="primary"
            />
            <div className="filter-name-text">{filter.ehnSpecialtyName}</div>
          </div>
        );
      });
    }

    if (this.props.primaryCareFilters.length) {
      return this.props.primaryCareFilters.map((filter, i) => {
        return (
          <div className="facility-filter-container">
            <Checkbox
              style={{
                display: "inline-block",
                width: "20%",
                marginLeft: 0,
              }}
              checked={this.props.filterObj[filter.ehnSpecialtyId]}
              onChange={() => this.handleFilterToggle(filter)}
              value={filter.ehnSpecialtyId}
              color="primary"
            />
            <div className="filter-name-text">{filter.ehnSpecialtyName}</div>
          </div>
        );
      });
    }

    if (this.props.pediatricCareFilters.length) {
      return this.props.pediatricCareFilters.map((filter, i) => {
        if (filter.specialties.length) {
          let type = "OPEN_DROPDOWN";
          return (
            <div
              className="filter-with-children-container"
              style={
                this.state.filtersDropDownObj &&
                this.state.filtersDropDownObj[filter.ehnSpecialtyId]
                  ? { height: "100%" }
                  : { height: 40 }
              }>
              {this.state.filtersDropDownObj &&
              this.state.filtersDropDownObj[filter.ehnSpecialtyId] ? (
                <i
                  className="fas fa-caret-down"
                  style={{ width: 10, marginRight: 10, cursor: "pointer" }}
                  onClick={() => this.handleDropDownToggle(filter, type)}
                />
              ) : (
                <i
                  className="fas fa-caret-right"
                  style={{ width: 10, marginRight: 10, cursor: "pointer" }}
                  onClick={() => this.handleDropDownToggle(filter, type)}
                />
              )}
              <Checkbox
                style={{
                  display: "inline-block",
                  width: "20%",
                  marginLeft: -10,
                }}
                checked={this.props.filterObj[filter.ehnSpecialtyId]}
                onChange={() => this.handleDropDownToggle(filter)}
                value={filter.ehnSpecialtyId}
                color="primary"
              />
              <div className="filter-name-text">{filter.ehnSpecialtyName}</div>
              {this.state.filtersDropDownObj &&
              this.state.filtersDropDownObj[filter.ehnSpecialtyId]
                ? filter.specialties.map((spec, i) => {
                    return (
                      <div style={{ height: "100%", paddingLeft: 60 }}>
                        <Checkbox
                          style={{
                            display: "inline-block",
                            width: "10%",
                          }}
                          checked={this.props.filterObj[spec.ehnSpecialtyId]}
                          onChange={() => this.handleFilterToggle(spec)}
                          value={spec.ehnSpecialtyId}
                          color="primary"
                        />
                        <div className="filter-name-text">
                          {spec.ehnSpecialtyName}
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          );
        } else {
          return (
            <div className="facility-filter-container">
              <Checkbox
                style={{
                  display: "inline-block",
                  width: "20%",
                  marginLeft: 10,
                }}
                checked={this.props.filterObj[filter.ehnSpecialtyId]}
                onChange={() => this.handleFilterToggle(filter)}
                value={filter.ehnSpecialtyId}
                color="primary"
              />
              <div className="filter-name-text">{filter.ehnSpecialtyName}</div>
            </div>
          );
        }
      });
    }
  };

  renderFilterCategoryTitle = () => {
    if (this.props.providerFilters && this.props.providerFilters.length) {
      return "Specialty Care";
    } else if (
      this.props.facilityFilters &&
      this.props.facilityFilters.length
    ) {
      return "Facilities";
    } else if (
      this.props.primaryCareFilters &&
      this.props.primaryCareFilters.length
    ) {
      return "Primary Care";
    } else if (
      this.props.pediatricCareFilters &&
      this.props.pediatricCareFilters.length
    ) {
      return "Pediatric Specialties";
    }
  };

  render() {
    const filtersView =
      this.props.filterObj && !this.props.__loadingFilters__ ? (
        <div>{this.renderFilterCategoryList()}</div>
      ) : null;

    return (
      <Card style={{ height: this.props.windowHeight, overflowY: "scroll" }}>
        <CardContent>
          <div style={{ fontSize: 20, fontWeight: "bold" }}>
            {this.renderFilterCategoryTitle()}
          </div>
          <div
            className="filters-view-container"
            style={{ height: window.innerHeight, paddingBottom: 200 }}>
            {filtersView}
          </div>
        </CardContent>
      </Card>
    );
  }
}
