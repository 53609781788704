import React, { Component } from "react";

import SearchIcon from "@material-ui/icons/Search";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import PlaceIcon from "@material-ui/icons/Place";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";

import { rangeOptions, filterKeys } from "../../../util/constants";
import MenuButton from "../../MenuButton";

const styles = {
  paper: {
    height: 50,
    boxShadow:
      "0px 2px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)",
  },
  label: {
    fontSize: 9,
    height: 50,
    minHeight: 50,
  },
  lightTooltip: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 11,
  },
  groupNumber: {
    backgroundColor: "#73d7ff",
    borderRadius: 5,
  },
  groupNumberText: {
    color: "black",
  },
};

class HeaderSection extends Component {
  constructor() {
    super();

    this.state = {
      tabValue: 0,
    };
  }

  componentDidMount() {
    if (
      this.props.searchType[0] === filterKeys.primaryCare ||
      this.props.searchType === filterKeys.primaryCare
    ) {
      this.setState({
        tabValue: 0,
      });
    } else if (
      this.props.searchType[0] === filterKeys.provider ||
      this.props.searchType === filterKeys.provider
    ) {
      this.setState({
        tabValue: 1,
      });
    } else if (
      this.props.searchType[0] === filterKeys.facility ||
      this.props.searchType === filterKeys.facility
    ) {
      this.setState({
        tabValue: 2,
      });
    } else if (
      this.props.searchType[0] === filterKeys.pediatric ||
      this.props.searchType === filterKeys.pediatric
    ) {
      this.setState({
        tabValue: 3,
      });
    }
  }

  handleToggleFilterChange = (event, tabValue) => {
    this.setState({ tabValue });
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.props.doSearch();
  };

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <div
          className="header-container"
          style={
            this.props.sectionsContainerOnTop
              ? { display: "none" }
              : { display: "block" }
          }>
          <div
            className="ehn-logo-container"
            onClick={() => this.props.goToSplashPage()}>
            <img
              alt="Employers Health Network"
              src={require("../../../assets/logo.png")}
              className="ehn-logo"
            />

            <p className="logo-text-coronavirus">
              Many providers have telemedicine service capabilities. Please
              contact the provider directly to confirm capabilities.
            </p>
          </div>
          <div className="keywords-input-container">
            <Paper square className={this.props.classes.paper}>
              <Tabs
                value={this.state.tabValue}
                onChange={this.handleToggleFilterChange}
                fullWidth
                style={{ minHeight: 50 }}
                indicatorColor="primary"
                textColor="primary">
                <Tab
                  label="PRIMARY CARE"
                  className={this.props.classes.label}
                  onClick={() =>
                    this.props.handleSearchTypeChange(filterKeys.primaryCare)
                  }
                  icon={
                    <Tooltip
                      title="Family Practice, Internal Medicine, Pediatricians"
                      placement="bottom">
                      <img
                        src={require("../../../assets/primary-care.png")}
                        style={{
                          height: 20,
                          width: 20,
                          marginTop: 0,
                        }}
                        alt="PRIMARY CARE"
                      />
                    </Tooltip>
                  }
                  style={{ height: 50, minHeight: 50, paddingTop: 0 }}
                />
                <Tab
                  label="SPECIALTY CARE"
                  className={this.props.classes.label}
                  onClick={() =>
                    this.props.handleSearchTypeChange(filterKeys.provider)
                  }
                  icon={
                    <Tooltip
                      title="Dermatologists, Cardiologists, Orthopedists, etc."
                      placement="bottom">
                      <img
                        src={require("../../../assets/specialties.png")}
                        style={{
                          height: 20,
                          width: 20,
                          marginTop: 0,
                        }}
                        alt="SPECIALTY CARE"
                      />
                    </Tooltip>
                  }
                  style={{ height: 50, minHeight: 50, paddingTop: 0 }}
                />
                <Tab
                  label="FACILITIES"
                  className={this.props.classes.label}
                  onClick={() =>
                    this.props.handleSearchTypeChange(filterKeys.facility)
                  }
                  icon={
                    <Tooltip
                      title="Hospitals, Clinics, Labs"
                      placement="bottom">
                      <img
                        src={require("../../../assets/facilities.png")}
                        style={{
                          height: 20,
                          width: 20,
                          marginTop: 0,
                        }}
                        alt="FACILITIES"
                      />
                    </Tooltip>
                  }
                  style={{ height: 50, minHeight: 50, paddingTop: 0 }}
                />
                <Tab
                  label="PEDIATRIC SPECIALTIES"
                  className={this.props.classes.label}
                  onClick={() =>
                    this.props.handleSearchTypeChange(filterKeys.pediatric)
                  }
                  icon={
                    <Tooltip
                      title="Pediatric Ophthalmologists, Pediatric Sports Medicine, Pediatric Cardiologists, etc."
                      placement="bottom">
                      <img
                        src={require("../../../assets/pediatric-specialties-blue.png")}
                        style={{
                          height: 20,
                          width: 20,
                          marginTop: 0,
                        }}
                        alt="PEDIATRIC SPECIALTIES"
                      />
                    </Tooltip>
                  }
                  style={{ height: 50, minHeight: 50, paddingTop: 0 }}
                />
              </Tabs>
            </Paper>

            <div className="block">
              <div className="keyword-input-container">
                <TextField
                  id="keywords-placeholder"
                  label="Search for Health Care Providers by Name or Keyword"
                  placeholder="e.g. Dr. Stevens, Eyes, Heart, Fever, etc."
                  style={{ height: 40, marginTop: 15 }}
                  fullWidth
                  onChange={(e) => this.props.handleSearchTextChange(e)}
                  onKeyPress={this.props.handleEnterKeyPress}
                  value={this.props.filterString}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment variant="filled" position="end">
                        <IconButton aria-label="Search For Keywords.">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="group-input-container">
                <Tooltip
                  classes={{ tooltip: this.props.classes.lightTooltip }}
                  title={
                    <img
                      src={require("../../../assets/samp-id-card.png")}
                      style={{
                        height: 300,
                        width: 500,
                        marginTop: 0,
                        backgroundColor: "white",
                      }}
                      alt="Sample ID Card"
                    />
                  }>
                  <TextField
                    disabled
                    required
                    className={this.props.classes.groupNumber}
                    InputProps={{
                      className: this.props.classes.groupNumberText,
                    }}
                    InputLabelProps={{
                      // className: this.props.classes.groupNumberText,
                      classes: {
                        root: this.props.classes.groupNumberText,
                        focused: this.props.classes.groupNumberText,
                      },
                    }}
                    id="group-placeholder"
                    label="Group ID"
                    placeholder="Enter a Group ID"
                    fullWidth
                    margin="normal"
                    style={{ height: 40 }}
                    onChange={(e) => this.props.handleGroupChange(e)}
                    onKeyPress={this.props.handleEnterKeyPress}
                    value={this.props.userGroup}
                    error={this.props.userGroupErr}
                  />
                </Tooltip>
              </div>
            </div>
            <div className="block">
              <div className="location-input-container">
                <TextField
                  id="location-placeholder"
                  label="Location"
                  placeholder="Enter a Zipcode or City and State."
                  fullWidth
                  margin="normal"
                  style={{ height: 40 }}
                  onChange={(e) => this.props.handleLocationChange(e)}
                  onKeyPress={this.props.handleEnterKeyPress}
                  value={this.props.userLocation}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment variant="filled" position="end">
                        <Tooltip title="Fetch Location" placement="left">
                          <IconButton
                            aria-label="Search For Location."
                            onClick={this.props.handleLocationIconClick}>
                            <PlaceIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="range-select-container">
                <TextField
                  id="range-select"
                  select
                  label="Range"
                  value={
                    typeof this.props.distance == "string"
                      ? parseInt(this.props.distance)
                      : this.props.distance
                  }
                  onChange={(e) => this.props.handleRangeSelect(e)}
                  style={{ width: "100%", height: 40 }} // was 40
                  margin="normal">
                  {rangeOptions.map((opt) => (
                    <MenuItem key={opt.key} value={opt.value}>
                      {opt.text}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
          </div>
          <div className="search-btn-toggle-filters-container">
            <Button
              variant="contained"
              type="submit"
              style={{
                height: 50,
                fontSize: 16,
                marginTop: -40,
                width: "100%",
                backgroundColor: "#216193",
                color: "white",
                minWidth: 140,
              }}>
              Search
            </Button>
            <MenuButton
              label="Can't find your provider?"
              options={[
                {
                  label: "Member",
                  onClick: this.props.handleCantFindYourProviderMember,
                },
                {
                  label: "Provider",
                  onClick: this.props.handleCantFindYourProvider,
                },
              ]}
            />
          </div>
        </div>
      </form>
    );
  }
}

export default withStyles(styles)(HeaderSection);
