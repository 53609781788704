import React, { Component } from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Tooltip from "@material-ui/core/Tooltip";

import { filterKeys } from "../../util/constants";

export default class SplashView extends Component {
  constructor() {
    super();

    this.state = {
      cardContainerStyles: {
        height: window.innerHeight - 100,
        width: window.innerWidth * 0.25,
        left: 80,
        zIndex: 10,
        position: "absolute",
        top: 40,
        background: "linear-gradient(to right, white, lightgrey)",
        borderRadius: 15,
      },
      cardContainerStyles2: {
        height: window.innerHeight - 1550,
        width: window.innerWidth * 0.5,
        left: window.innerWidth * 0.4,
        zIndex: 10,
        position: "absolute",
        top: 20,
        background: "linear-gradient(to right, white, lightgrey)",
        borderRadius: 15,
      },
      splashFilterBtnsContainerStyles: {
        height: window.innerHeight * 0.25,
        width: window.innerWidth * 0.6,
        position: "absolute",
        zIndex: 10,
        left: window.innerWidth * 0.25 + 140,
        bottom: 80,
        display: "block",
      },
      cardFilterBtnStyles: {
        height: "100%",
        width: "23%",
        borderRadius: 10,
        display: "inline-block",
        margin: "1%",
        background: "rgb(19, 19, 73)",
        boxShadow: "0px 5px 20px -5px rgb(19, 19, 73)",
        cursor: "pointer",
      },
      bodyBackgroundColor: "rgb(19, 19, 73)",
      showInvertedSplashBtns: true, // defaults to desktop view
      isMobileView: false,
      hovered: false,
    };
  }

  componentDidMount() {
    const _this = this;

    // Scroll to top of the page
    window.scroll(0, 0);

    const handleResizeEvent = () => {
      if (window.innerWidth <= 1100) {
        // For any js styling for mobile search -- mobile view styles
        _this.setState({
          cardContainerStyles: {
            width: "100%",
            zIndex: 10,
            top: 10,
            background: "linear-gradient(to right, white, lightgrey)",
          },
          cardContainerStyles2: {
            width: "100%",
            zIndex: 10,
            background: "linear-gradient(to right, white, lightgrey)",
          },
          splashFilterBtnsContainerStyles: {
            backgroundColor: "rgb(19, 19, 73)",
            paddingBottom: 20,
          },
          cardFilterBtnStyles: {
            height: 140,
            width: "90%",
            marginLeft: "5%",
            marginTop: "5%",
            background: "white",
            cursor: "pointer",
          },
          bodyBackgroundColor: "rgb(19, 19, 73)",
          showInvertedSplashBtns: false,
          isMobileView: true,
        });
      } else {
        // desktop view styles
        _this.setState({
          cardContainerStyles: {
            height: window.innerHeight - 100,
            width: window.innerWidth * 0.25,
            left: 80,
            zIndex: 10,
            position: "absolute",
            top: 40,
            background: "linear-gradient(to right, white, lightgrey)",
            borderRadius: 15,
          },
          cardContainerStyles2: {
            height: window.innerHeight - 1510,
            width: window.innerWidth * 0.5,
            left: window.innerWidth * 0.4,
            //margin: '0 auto',
            zIndex: 10,
            position: "absolute",
            top: 20,
            background: "linear-gradient(to right, white, lightgrey)",
            borderRadius: 15,
          },
          splashFilterBtnsContainerStyles: {
            height: window.innerHeight * 0.25,
            width: window.innerWidth * 0.6,
            position: "absolute",
            zIndex: 10,
            left: window.innerWidth * 0.25 + 140,
            bottom: 80,
            display: "block",
          },
          cardFilterBtnStyles: {
            height: "100%",
            width: "23%",
            borderRadius: 10,
            display: "inline-block",
            margin: "1%",
            background: "rgb(19, 19, 73)",
            boxShadow: "0px 5px 20px -5px rgb(19, 19, 73)",
            cursor: "pointer",
          },
          bodyBackgroundColor: "white",
          showInvertedSplashBtns: true,
          isMobileView: false,
        });
      }
    };

    // listen for dynamic resizing - recursive case
    window.addEventListener("resize", handleResizeEvent);

    // handles base mobile case on load
    if (window.innerWidth <= 1100) {
      // For any js styling for mobile search
      this.setState({
        cardContainerStyles: {
          width: "100%",
          zIndex: 10,
          top: 10,
          background: "linear-gradient(to right, white, lightgrey)",
        },
        splashFilterBtnsContainerStyles: {
          backgroundColor: "rgb(19, 19, 73)",
          paddingBottom: 20,
        },
        cardFilterBtnStyles: {
          height: 140,
          width: "90%",
          marginLeft: "5%",
          marginTop: "5%",
          background: "white",
        },
        bodyBackgroundColor: "rgb(19, 19, 73)",
        showInvertedSplashBtns: false,
        isMobileView: true,
      });
    }
  }

  // TODO: Run components through profiler, my be rerendering to many times
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props || nextState !== this.state) {
      return true;
    } else {
      return false;
    }
  }

  componentWillMount() {
    // there is a memory leak, handle
    //... state getting updated when component unmounts
  }

  render() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "block",
          backgroundColor: this.state.bodyBackgroundColor,
        }}>
        {/* <WhatsNew/> */}
        <Card id="cardContainer" style={this.state.cardContainerStyles}>
          <CardContent style={{ textAlign: "left" }}>
            <div style={{ textAlign: "center" }}>
              <p className="powered-by-splash-text">Powered by</p>
              <img
                src={require("../../assets/CenterStack_Standard.png")}
                width={"70%"}
                alt="Logo"
              />
            </div>
            <div style={{ textAlign: "center" }}>
              <img
                src={require("../../assets/GCISD Logo.png")}
                width={"45%"}
                alt="Logo"
              />
            </div>
            <div className="get-care-you-need-text">Get the care you need.</div>
            <div className="search-for-splash-text-bold">
              Search for healthcare facilities and providers in your network.
            </div>
            <p className="search-for-splash-text">
              Whether you're searching for a family doctor, an urgent care
              clinic or physical therapy center, we make finding a physician or
              facility in your plan fast, simple and convenient. Use our
              provider search to locate a doctor or healthcare center in your
              network and near you.
            </p>
            <p className="search-for-splash-text-coronavirus">
              Your Baylor Scott & White – Quality Alliance network provides you
              access to facilities and providers in the following 11 counties:
            </p>
            <p className="search-for-splash-text-coronavirus">
              Collin, Dallas, Denton, Ellis, Hood, Hunt, Johnson, Kaufman,
              Parker, Rockwall, and Tarrant
            </p>
            <img
              src={require("../../assets/logo.png")}
              width={120}
              alt="Logo"
            />
          </CardContent>
          {!this.state.isMobileView ? (
            <div
              className="block"
              style={{
                position: "absolute",
                width: "90%",
                marginLeft: "5%",
                bottom: 20,
              }}>
              <div
                style={{
                  display: "inline-block",
                  width: "85%",
                  verticalAlign: "middle",
                  fontSize: 14,
                  fontStyle: "italic",
                }}>
                Select the type of care that is right for you!
              </div>
              <i
                className="fas fa-arrow-right fa-2x"
                style={{
                  color: "grey",
                  display: "inline-block",
                  width: "8%",
                  verticalAlign: "middle",
                }}
              />
            </div>
          ) : (
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <div
                style={{
                  width: "100%",
                  verticalAlign: "middle",
                  textAlign: "center",
                  fontSize: 14,
                  fontStyle: "italic",
                }}>
                Select the type of care that is right for you!
              </div>
              <i
                className="fas fa-chevron-down fa-2x"
                style={{
                  color: "grey",
                  textAlign: "center",
                  marginTop: 10,
                  width: "100%",
                  verticalAlign: "middle",
                }}
              />
            </div>
          )}
        </Card>
        {/*<Card id="cardContainer2" style={this.state.cardContainerStyles2}>
					<CardContent style={{ textAlign: 'left' }}>
						<p style={{fontSize: 16}}>Are you a prospective Whole Foods Market member for 2022? <a href="https://wfm.employershealthnetwork.com/find-a-doctor/" target="_blank">Click here</a> to learn more about our network and how to find a provider.</p>
						</CardContent>
				</Card>*/}
        <div style={this.state.splashFilterBtnsContainerStyles}>
          <Tooltip title="Click for Primary Care.">
            <Card
              onClick={() =>
                this.props.splashSelectsFilter(filterKeys.primaryCare)
              }
              style={this.state.cardFilterBtnStyles}>
              <div
                className="logo-title-container"
                style={{
                  display: "block",
                  width: "90%",
                  marginLeft: "5%",
                  paddingTop: 10,
                  height: this.state.isMobileView ? 60 : "auto",
                }}>
                <img
                  src={
                    this.state.showInvertedSplashBtns
                      ? require("../../assets/primary-care-inverted.png")
                      : require("../../assets/primary-care.png")
                  }
                  style={
                    this.state.isMobileView
                      ? {
                          maxWidth: 60,
                          maxHeight: 60,
                          display: "inline-block",
                          verticalAlign: "middle",
                        }
                      : {
                          width: "15%",
                          height: "90%",
                          display: "inline-block",
                          verticalAlign: "middle",
                        }
                  }
                  alt="PRIMARY CARE"
                />
                <div
                  className="splash-btn-title"
                  style={this.state.isMobileView ? { marginTop: 20 } : null}>
                  PRIMARY CARE
                </div>
              </div>
              <div className="splash-btn-desc">
                Family Practice, Internal Medicine, Pediatricians
              </div>
            </Card>
          </Tooltip>
          <Tooltip title="Click for Pediatric Specialties.">
            <Card
              onClick={() =>
                this.props.splashSelectsFilter(filterKeys.pediatric)
              }
              style={this.state.cardFilterBtnStyles}>
              <div
                className="logo-title-container"
                style={{
                  display: "block",
                  width: "90%",
                  marginLeft: "5%",
                  paddingTop: 10,
                }}>
                <img
                  src={
                    this.state.showInvertedSplashBtns
                      ? require("../../assets/pediatric-specialties.png")
                      : require("../../assets/pediatric-specialties-blue.png")
                  }
                  style={
                    this.state.isMobileView
                      ? {
                          maxWidth: 60,
                          maxHeight: 60,
                          display: "inline-block",
                          verticalAlign: "middle",
                        }
                      : {
                          width: "15%",
                          height: "90%",
                          display: "inline-block",
                          verticalAlign: "middle",
                        }
                  }
                  alt="PEDIATRIC SPECIALTIES"
                />
                <div className="splash-btn-title">PEDIATRIC SPECIALTIES</div>
              </div>
              <div className="splash-btn-desc">
                Pediatric Ophthalmologists, Pediatric Sports Medicine, Pediatric
                Cardiologists
              </div>
            </Card>
          </Tooltip>
          <Tooltip title="Click for Specialty Care.">
            <Card
              onClick={() =>
                this.props.splashSelectsFilter(filterKeys.provider)
              }
              style={this.state.cardFilterBtnStyles}>
              <div
                className="logo-title-container"
                style={{
                  display: "block",
                  width: "90%",
                  marginLeft: "5%",
                  paddingTop: 10,
                }}>
                <img
                  src={
                    this.state.showInvertedSplashBtns
                      ? require("../../assets/specialties-inverted.png")
                      : require("../../assets/specialties.png")
                  }
                  style={
                    this.state.isMobileView
                      ? {
                          maxWidth: 60,
                          maxHeight: 60,
                          display: "inline-block",
                          verticalAlign: "middle",
                        }
                      : {
                          width: "15%",
                          height: "90%",
                          display: "inline-block",
                          verticalAlign: "middle",
                        }
                  }
                  alt="SPECIALTY CARE"
                />
                <div className="splash-btn-title">SPECIALTY CARE</div>
              </div>
              <div className="splash-btn-desc">
                Dermatologists, Cardiologists, Orthopedists
              </div>
            </Card>
          </Tooltip>
          <Tooltip title="Click for Facilities.">
            <Card
              onClick={() =>
                this.props.splashSelectsFilter(filterKeys.facility)
              }
              style={this.state.cardFilterBtnStyles}>
              <div
                className="logo-title-container"
                style={{
                  display: "block",
                  width: "90%",
                  marginLeft: "5%",
                  paddingTop: 10,
                }}>
                <img
                  src={
                    this.state.showInvertedSplashBtns
                      ? require("../../assets/facilities-inverted.png")
                      : require("../../assets/facilities.png")
                  }
                  style={
                    this.state.isMobileView
                      ? {
                          maxWidth: 60,
                          maxHeight: 60,
                          display: "inline-block",
                          verticalAlign: "middle",
                        }
                      : {
                          width: "15%",
                          height: "90%",
                          display: "inline-block",
                          verticalAlign: "middle",
                        }
                  }
                  alt="FACILITIES"
                />
                <div className="splash-btn-title">FACILITIES</div>
              </div>
              <div className="splash-btn-desc">Hospitals, Clinics, Labs</div>
            </Card>
          </Tooltip>
        </div>
        <div className="splash-panel-background">
          <div
            style={{
              fontSize: 12,
              color: "white",
              fontWeight: "bold",
              textAlign: "center",
              position: "absolute",
              bottom: 10,
              left: 100,
            }}>
            &copy; 2019 Employers Health Network.
            <div style={{ marginTop: 1 }}>All Rights Reserved.</div>
          </div>
        </div>
        <img
          className="splash-background"
          src={require("../../assets/splash-background.jpg")}
          alt=""
        />
        <div
          style={{
            fontSize: 12,
            color: "rgb(19, 19, 73)",
            fontWeight: "bold",
            textAlign: "center",
            display: this.state.isMobileView ? "block" : "none",
            paddingBottom: 5,
            paddingTop: 5,
          }}>
          &copy; 2019 Employers Health Network.
          <div style={{ marginTop: 1 }}>All Rights Reserved.</div>
        </div>
      </div>
    );
  }
}
