import * as _ from 'lodash';
// Thought lodash was deleted, how is this working

export const buildQueryParams = (params, includeEmptyOrNull = false) => {
	let queryParams = _.reduce(
		params,
		(qp, v, k) => {
			const isString = _.isString(v);
			const isNull = v === null;
			const isUndefined = typeof v === 'undefined';

			// Non-empty qualifies as not null and, if it's a string, that it's length is greater than zero.
			const isNonEmpty = !isNull && (!isString || v.length > 0);

			// If the value is defined and is non-empty include it, or if it is empty and we want to include
			// empties, append it in the query string. Empty values would be included in order to utilize server side validation.
			if (!isUndefined && (isNonEmpty || (!isNonEmpty && includeEmptyOrNull))) {
				qp += (qp.length ? '&' : '') + k + '=' + (_.isString(v) ? encodeURIComponent(v) : v);
			}
			return qp;
		},
		''
	);
	return queryParams.length ? '?' + queryParams : '';
};
