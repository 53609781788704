import React from "react";
import Button from "@material-ui/core/Button";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";

class MenuButton extends React.Component {
  state = {
    open: false,
  };

  handleToggle = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    const { options, label, buttonStyle, popperStyle } = this.props;

    return (
      <Button
        buttonRef={(node) => {
          this.anchorEl = node;
        }}
        aria-owns={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={this.handleToggle}
        style={buttonStyle}>
        {label || ""}
        <Popper
          placement="bottom"
          open={open}
          anchorEl={this.anchorEl}
          transition
          style={popperStyle ? popperStyle : { width: "155px" }}>
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              placement="bottom"
              style={{ transformOrigin: "center bottom" }}>
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList>
                    {options.map(({ label, onClick }) => (
                      <MenuItem key={label} onClick={onClick}>
                        {label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Button>
    );
  }
}

export default MenuButton;
