import React from "react";
import Joi from "joi-browser";
import FormBase from "./FormBase";

class DataIssueFeedbackForm extends FormBase {
  state = {
    data: {
      id: "",
      dataType: "",
      provProfessionalId: 0,
      provFirstName: "",
      provLastName: "",
      provGender: "",
      name: "",
      providerSpecialty: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      contactPhone: "",
      other: "",
    },
    errors: {},
  };

  constructor(props) {
    super(props);
    this.API_CALL_POINT = "DataIssueFeedback";
    this.ON_CREATE_IGNORE_ID = false;
  }

  componentDidMount() {
    const { selectedProvider: model } = this.props;
    if (!model) return;

    this.setState({
      data: this.mapToViewModel(model),
    });
  }

  mapToViewModel(entity) {
    return {
      id: entity.id,
      dataType: entity.dataType,
      provProfessionalId: entity.provProfessionalId,
      provFirstName: entity.provFirstName,
      provLastName: entity.provLastName,
      provGender: entity.provGender,
      providerSpecialty: entity.providerSpecialty,
      name: entity.name,
      address1: entity.address1,
      address2: entity.address2,
      city: entity.city,
      state: entity.state,
      zip: entity.zip,
      contactPhone: entity.contactPhone,
      other: entity.other,
    };
  }

  schema = {
    id: Joi.string().label("Id"),
    dataType: Joi.string().label("dataType"),
    provProfessionalId: Joi.string().label("provProfessionalId"),
    provFirstName: Joi.string().label("First Name"),
    provLastName: Joi.string().label("Last Name"),
    provGender: Joi.string().label("Gender"),
    name: Joi.string().label("Provider Name"),
    address1: Joi.string().label("Address Line 1"),
    address2: Joi.string().label("Address Line 2"),
    city: Joi.string().label("City"),
    state: Joi.string().label("State"),
    zip: Joi.string().label("Zip"),
    contactPhone: Joi.string().label("Contact Phone #"),
    other: Joi.string().label("Other"),
  };

  render() {
    return (
      <React.Fragment>
        {this.renderTextInput("provFirstName", "First Name", "text", {
          width: 150,
        })}
        {this.renderTextInput("provLastName", "Last Name", "text", {
          width: 150,
        })}
        {this.renderTextInput("provGender", "Gender", "text", { width: 100 })}
        {/* TODO 2021-06-28 Set values for a dropdown? */}
        <br />
        <br />
        {this.renderTextInput("name", "Provider Name", "text", { width: 200 })}
        <br />
        {this.renderTextInput("providerSpecialty", "Specialty")}
        <br />
        <br />
        {this.renderTextInput("address1", "Address Line 1")}
        <br />
        {this.renderTextInput("address2", "Address Line 2")}
        <br />
        {this.renderTextInput("city", "City", "text", { width: 225 })}
        {this.renderTextInput("state", "State", "text", { width: 60 })}
        {this.renderTextInput("zip", "Zip", "text", { width: 100 })}
        <br />
        <br />
        {this.renderTextInput("contactPhone", "Contact Phone #")}
        <br />
        <br />
        {this.renderTextareaInput("other", "Other")}
      </React.Fragment>
    );
  }
}

export default DataIssueFeedbackForm;
