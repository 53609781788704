/* eslint-disable eqeqeq */
import React, { Component } from "react";

import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import PropTypes from "prop-types";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { Pagination, Dimmer, Label } from "semantic-ui-react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AppBar from "@material-ui/core/AppBar";
import OfficeHoursModal from "../OfficeHoursModal";
import { sortOptions } from "../../../util/constants";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

const CustomTableCell = withStyles(() => ({
  head: {
    paddingRight: "7px !important",
    paddingLeft: "7px",
  },
  body: {
    paddingRight: "7px !important",
    paddingLeft: "7px",
    "&:last-child": {
      whiteSpace: "nowrap",
    },
  },
}))(TableCell);

const CustomTableRow = withStyles(() => ({
  root: {
    height: "fit-content",
  },
}))(TableRow);

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class SearchResultCard extends Component {
  state = {
    value: 0,
    locations: [],
  };

  fetchLocations = () => {
    return fetch(
      `${process.env.REACT_APP_API_URL}/provider/providerLocations?professionalId=${this.props.provider.provProfessionalId}&practiceId=${this.props.provider.practiceId}`,
      {
        method: "get",
        headers: {
          dataType: "json",
          contentType: "application/json",
        },
      },
    )
      .then((response) => {
        if (
          response.status !== 200 &&
          response.status !== 201 &&
          response.status !== 201
        ) {
          console.log(
            "Error: Failed to fetch filters. Status Code: " + response.status,
          );
          this.setState({
            ...this.state,
            locationsLoading: false,
          });
          return;
        }
        response.json().then((res) => {
          console.log("resssss", res);
          this.setState({
            ...this.state,
            locations: res,
            locationsLoading: false,
          });
          return;
        });
      })
      .catch((err) => {
        console.log("Error fetching locations.", err);
        this.setState({
          ...this.state,
          locationsLoading: false,
        });
        return;
      });
  };

  handleChange = (event, value) => {
    event.preventDefault();
    event.stopPropagation();
    if (value === 2) {
      this.setState({
        ...this.state,
        value,
        locations: [],
        locationsLoading: true,
      });
      this.fetchLocations();
    }
    this.setState({
      ...this.state,
      value,
    });
  };

  getResultId = () => {
    return this.props.providerIndex + 1 + (this.props.page - 1) * 10;
  };

  goToMapMarker = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.updateMapIconActiveIdState(this.props.provider.id);
    this.props.handleResultCardClick(this.props.provider);
  };

  clickLink = (event) => {
    event.stopPropagation();
  };

  renderTelemedicine = () => {
    if (this.props.provider.provTelemedicine == true) {
      return (
        <Grid item xs={6}>
          <h4>Telemedicine</h4>
          Yes
        </Grid>
      );
    } else if (this.props.provider.provTelemedicine == false) {
      return (
        <Grid item xs={6}>
          <h4>Telemedicine</h4>
          No
        </Grid>
      );
    } else {
      return (
        <Grid item xs={6}>
          <h4>Telemedicine</h4>
          Contact provider’s office for available services
        </Grid>
      );
    }
  };

  renderHandicap = () => {
    if (this.props.provider.provHandicap == true) {
      return (
        <Grid item xs={6}>
          <h4>Handicap Accessible</h4>
          Yes
        </Grid>
      );
    } else if (this.props.provider.provHandicap == false) {
      return (
        <Grid item xs={6}>
          <h4>Handicap Accessible</h4>
          No
        </Grid>
      );
    } else {
      return (
        <Grid item xs={6}>
          <h4>Handicap Accessible</h4>
          Contact provider’s office for information
        </Grid>
      );
    }
  };

  renderAcceptingNewPatients = () => {
    if (this.props.provider.provAcceptingNewPatients == true) {
      return (
        <div>
          <h4>Accepting New Patients</h4>
          Yes
        </div>
      );
    } else if (this.props.provider.provAcceptingNewPatients == false) {
      return (
        <div>
          <h4>Accepting New Patients</h4>
          No
        </div>
      );
    } else {
      return (
        <div>
          <h4>Accepting New Patients</h4>
          Contact provider’s office for information
        </div>
      );
    }
  };

  renderBoardCert = () => {
    const boardCertToolTip =
      "A practioner who is board certified has satisfied the requirements/standards of a nationally recognized specilaty board.";
    if (this.props.provider.provBoardCert == true) {
      return (
        <Grid item xs={6}>
          <Tooltip title={boardCertToolTip}>
            <div>
              <h4>Board Certified</h4>
              Yes
            </div>
          </Tooltip>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={6}>
          <Tooltip title={boardCertToolTip}>
            <div>
              <h4>Board Certified</h4>
              No
            </div>
          </Tooltip>
        </Grid>
      );
    }
  };

  renderLanguages = () => {
    if (this.props.provider.provLanguages != undefined) {
      return (
        <Grid item xs={6}>
          <h4>Languages</h4>
          {this.props.provider.provLanguages.map((language, i) => [
            i > 0 && ", ",
            language,
          ])}
        </Grid>
      );
    } else {
      return (
        <Grid item xs={6}>
          <h4>Languages</h4>
          Contact provider’s office for information
        </Grid>
      );
    }
  };

  renderTreatmentAgeRange = () => {
    if (this.props.provider.provTreatmentAgeRange != undefined) {
      return (
        <Grid item xs={6}>
          <h4>Treatment Age Range</h4>
          {this.props.provider.provTreatmentAgeRange}
        </Grid>
      );
    } else {
      return (
        <Grid item xs={6}>
          <h4>Treatment Age Range</h4>
          Contact provider’s office for information
        </Grid>
      );
    }
  };

  renderHospitalAffiliations = () => {
    if (this.props.provider.provHospitals != undefined) {
      return (
        <Grid item xs={6}>
          <h4 style={{ margin: 0 }}>Hospital Affiliations</h4>
          {this.props.provider.provHospitals.map((hospital, i) => [
            <p style={{ margin: 0, padding: 0 }}>{hospital}</p>,
          ])}
        </Grid>
      );
    } else {
      return (
        <Grid item xs={6}>
          <h4>Hospital Affiliations</h4>
          Contact provider’s office for information
        </Grid>
      );
    }
  };

  renderWebsite = (name) => {
    if (name != null && name.includes("Amazon Care")) {
      return (
        <div>
          <a
            href="https://www.amazon.care"
            onClick={(event) => this.clickLink(event)}
            target="_blank"
            rel="noopener noreferrer">
            www.amazon.care
          </a>
        </div>
      );
    } else if (
      name != null &&
      name == "Quest Diagnostics Clinical Laboratories, Inc."
    ) {
      return (
        <div>
          <a
            href="https://www.questdiagnostics.com/locations/search.html/78070/50/1"
            onClick={(event) => this.clickLink(event)}
            target="_blank"
            rel="noopener noreferrer">
            Quest Diagnostics Test Sites
          </a>
        </div>
      );
    } else {
      return null;
    }
  };

  renderCardSummary = (provider, mapIconActiveId, showAcceptingNewPatients) => {
    return (
      <Grid container spacing={8}>
        <Grid item xs={6}>
          <div className="card-content-container">
            <div className="card-title-container">
              <h4 className="card-title">
                {`${provider.provFirstName} ${provider.provLastName}`}
              </h4>
              <h4 className="card-title">{this.props.provider.name}</h4>
            </div>
            <div className="block" style={{ marginBottom: 10 }}>
              <div className="specialties-text">
                {provider.providerSpecialty}
                {provider.provdierSecondarySpecialty
                  ? // change to provider when backend changes
                    `, ${provider.provdierSecondarySpecialty}`
                  : ""}
              </div>
            </div>
            <div>{provider.address1}</div>
            <div>{provider.address2}</div>
            <div>
              {provider.city}, {provider.state} {provider.zip}
            </div>
            {this.renderWebsite(this.props.provider.name)}
            <div>
              <a
                href={`tel:${provider.contactPhone}`}
                onClick={(event) => this.clickLink(event)}>
                {provider.contactPhone}
              </a>
            </div>
            <div className="miles-away-text">
              {provider.distanceFromRefLocation > 0 ? (
                <span>
                  {parseFloat(
                    Math.round(provider.distanceFromRefLocation * 100) / 100,
                  ).toFixed(2)}{" "}
                  miles away
                </span>
              ) : (
                <span>National Provider</span>
              )}
            </div>
            <Link
              style={{ width: 100, textAlign: "left" }}
              variant="button"
              underline="none"
              onClick={(event) =>
                this.props.onDataIssueButtonClick(event, provider)
              }>
              Need to update information?
            </Link>
          </div>
        </Grid>
        <Grid item xs={5}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}>
            {showAcceptingNewPatients
              ? this.renderAcceptingNewPatients()
              : null}
          </div>
        </Grid>
        <Grid item xs={1}>
          <div className="card-label">
            <Tooltip title="Find Map Marker">
              <Chip
                aria-label="Delete"
                label={this.getResultId()}
                onClick={(event) => this.goToMapMarker(event)}
                style={
                  mapIconActiveId && mapIconActiveId == provider.id
                    ? {
                        backgroundColor: "white",
                        color: "#216193",
                        fontSize: 14,
                        border: "3px solid #216193",
                      }
                    : {
                        backgroundColor: "#216193",
                        color: "white",
                        fontSize: 14,
                        boxShadow: "0px 5px 20px -5px black",
                      }
                }
              />
            </Tooltip>
          </div>
        </Grid>
      </Grid>
    );
  };

  renderLocations = () => {
    if (this.state.locationsLoading) {
      return <div></div>;
    }
    return (
      <>
        <Table size="small" aria-label="a dense table" padding="dense">
          <TableHead>
            <CustomTableRow>
              <CustomTableCell>Practice</CustomTableCell>
              <CustomTableCell>Address</CustomTableCell>
              <CustomTableCell>City</CustomTableCell>
              <CustomTableCell>State</CustomTableCell>
              <CustomTableCell>Zip</CustomTableCell>
              <CustomTableCell>Phone</CustomTableCell>
            </CustomTableRow>
          </TableHead>
          {this.state.locations && this.state.locations.length ? (
            <TableBody>
              {this.state.locations.map((row) => (
                <TableRow
                  key={`${row.practiceName}-${row.practiceAddressLine1}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <CustomTableCell component="th" scope="row">
                    {row.practiceName || ""}
                  </CustomTableCell>
                  <CustomTableCell>{`${row.practiceAddressLine1 || ""}${
                    row.practiceAddressLine2
                      ? `, ${row.practiceAddressLine2}`
                      : ""
                  }`}</CustomTableCell>
                  <CustomTableCell>{row.practiceCity || ""}</CustomTableCell>
                  <CustomTableCell>{row.practiceState || ""}</CustomTableCell>
                  <CustomTableCell>{row.practiceZip || ""}</CustomTableCell>
                  <CustomTableCell>{row.practicePhone || ""}</CustomTableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : null}
        </Table>
        {!this.state.locations || !this.state.locations.length ? (
          <div
            style={{ width: "100%", marginTop: "10px", textAlign: "center" }}>
            No Additional Locations
          </div>
        ) : null}
      </>
    );
  };

  renderCardDetails = (provider, value, handleChange) => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <AppBar position="static">
            <Tabs value={value} onChange={handleChange}>
              <Tab label="General Overview" />
              <Tab label="Provider Information" />
              <Tab label="Locations" />
            </Tabs>
          </AppBar>
        </Grid>
        <Grid item xs={12}>
          {value === 0 && (
            <TabContainer>
              <Grid container spacing={8}>
                {this.renderTelemedicine()}
                {this.renderTreatmentAgeRange()}
                {this.renderHandicap()}
                <OfficeHoursModal provider={provider} />
              </Grid>
            </TabContainer>
          )}
          {value === 1 && (
            <TabContainer>
              <Grid container spacing={8} alignItems="center">
                <Grid item xs={6}>
                  <h4>Gender</h4>
                  {provider.provGender}
                </Grid>
                {this.renderLanguages()}
                {this.renderBoardCert()}
                {this.renderHospitalAffiliations()}
              </Grid>
            </TabContainer>
          )}
          {value === 2 && <TabContainer>{this.renderLocations()}</TabContainer>}
        </Grid>
      </Grid>
    );
  };

  facilityCard = (provider, mapIconActiveId) => {
    return (
      <div className="result-item-container">
        <Paper
          id={provider.id}
          style={{ padding: 10, marginTop: 10 }}
          elevation={mapIconActiveId && mapIconActiveId == provider.id ? 5 : 1}>
          {this.renderCardSummary(provider, mapIconActiveId, false)}
        </Paper>
      </div>
    );
  };

  defaultCard = (provider, mapIconActiveId, value, handleChange) => {
    return (
      <div className="result-item-container">
        <ExpansionPanel
          key={this.key}
          id={provider.id}
          style={{ padding: 10, marginTop: 10 }}
          elevation={mapIconActiveId && mapIconActiveId == provider.id ? 5 : 1}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            {this.renderCardSummary(provider, mapIconActiveId, true)}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {this.renderCardDetails(provider, value, handleChange)}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  };

  render() {
    const { value } = this.state;
    const { provider, mapIconActiveId } = this.props;
    if (this.props.searchType[0] == "Facility") {
      return this.facilityCard(provider, mapIconActiveId);
    } else {
      return this.defaultCard(
        provider,
        mapIconActiveId,
        value,
        this.handleChange,
      );
    }
  }
}

export default class ResultsListSection extends Component {
  constructor() {
    super();

    this.state = {
      mapIconActiveId: undefined,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.searchHidden) {
      window.scrollTo(0, 0);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props || nextState !== this.state) {
      return true;
    } else {
      return false;
    }
  }

  updateMapIconActiveIdState = (mapIconActiveId) => {
    this.setState({
      mapIconActiveId,
    });
  };

  getResultsHeaderStr = () => {
    let resultsHeaderStr;
    let searchHiddenPaddingOffset = 70;

    if (!this.props.searchResults.length) {
      if (this.props.userLocation && this.props.showResultsLocationMsg) {
        resultsHeaderStr = (
          <div
            style={
              this.props.searchHidden
                ? { paddingTop: searchHiddenPaddingOffset }
                : { paddingTop: 0 }
            }>{`0 Results at ${this.props.userLocation}`}</div>
        );
      } else {
        resultsHeaderStr = (
          <div
            style={
              this.props.searchHidden
                ? { paddingTop: searchHiddenPaddingOffset }
                : { paddingTop: 0 }
            }>
            0 Results
          </div>
        );
      }
    } else if (this.props.recordCount == 1) {
      resultsHeaderStr = (
        <div
          style={
            this.props.searchHidden ? { paddingTop: 0 } : { paddingTop: 0 }
          }>{`${this.props.recordCount} Result`}</div>
      );
    } else if (this.props.recordCount > 1) {
      resultsHeaderStr = (
        <div
          style={
            this.props.searchHidden ? { paddingTop: 0 } : { paddingTop: 0 }
          }>{`${this.props.recordCount} Results`}</div>
      );
    }

    return resultsHeaderStr;
  };

  renderFilterLabels = () => {
    return this.props.specialty.length ? (
      <div>
        {this.props.specialty.map((filter, i) => {
          return (
            <Label
              style={{ margin: 5 }}
              as="a"
              content={
                filter.data
                  ? filter.data.ehnSpecialtyName
                  : filter.ehnSpecialtyName
              }
              key={`filter-chip-${i}`}
              onRemove={() => this.props.setDeleteFilterType(filter)}
            />
          );
        })}
      </div>
    ) : (
      <div />
    );
  };

  renderSearchResults = () => {
    let resultsHeaderStr = this.getResultsHeaderStr();
    var searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("page");
    searchParams.set("size", this.props.recordCount);
    const urlQueryString = searchParams.toString();
    const styles = (theme) => ({
      icon: {
        fill: "blue",
      },
      root: {
        backgroundColor: "#73d7ff",
        borderRadius: "5%",
      },
    });

    const Selectlist = ({ classes }) => (
      <Select
        className={classes.select}
        inputProps={{
          classes: {
            icon: classes.icon,
            root: classes.root,
          },
        }}
        style={{
          fontSize: 12,
          width: 100,
          height: 50,
        }}
        label="Sort by"
        disableUnderline
        value={this.props.sort}
        onChange={(e) => this.props.handleSortChange(e)}>
        {sortOptions.map((option) => (
          <MenuItem key={option.key} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    );

    const SelectWithStyles = withStyles(styles)(Selectlist);

    return (
      <React.Fragment>
        <div>
          <div style={{ fontSize: 20, fontWeight: "bold" }}>
            <SelectWithStyles />
            {resultsHeaderStr}

            <Link
              href={`${process.env.REACT_APP_API_ROOT_URL}/PdfExtract/Providers?${urlQueryString}`}
              style={{ width: 100, textAlign: "left" }}
              variant="button"
              underline="none"
              target="_blank">
              Download as PDF
            </Link>
            <Tooltip title="Limited to the first 1,000 providers">
              <InfoIcon />
            </Tooltip>
          </div>
          <div
            id="filter-label-container"
            style={{ overflowY: "scroll", height: "100%", maxHeight: 140 }}>
            {this.renderFilterStringLabel()}
            {this.renderFilterLabels()}
          </div>
          {this.props.specialty.length ||
          (this.props.filterString && this.props.showFilterStringLabel) ? (
            <div style={{ paddingTop: 20 }}>
              <Button onClick={this.props.clearFilters} color="primary">
                Clear Filters
              </Button>
            </div>
          ) : (
            <div />
          )}
          <Pagination
            pointing
            secondary
            siblingRange={2}
            firstItem={null}
            lastItem={null}
            onPageChange={(e) => this.props.handlePaginationChange(e)}
            activePage={this.props.page}
            totalPages={this.props.pageCount}
            style={{ marginBottom: 5, marginTop: 5 }}
          />
          <div
            className="search-results-container"
            style={{ height: window.innerHeight }}>
            {this.props.searchResults && this.props.searchResults.length
              ? this.props.searchResults.map((res, i) => {
                  return (
                    <SearchResultCard
                      key={res.id}
                      provider={res}
                      providerIndex={i}
                      page={this.props.page}
                      handleResultCardClick={(provider) =>
                        this.props.handleResultCardClick(provider)
                      }
                      updateMapIconActiveIdState={(id) =>
                        this.updateMapIconActiveIdState(id)
                      }
                      mapIconActiveId={this.state.mapIconActiveId}
                      searchType={this.props.searchType}
                      onDataIssueButtonClick={this.props.onDataIssueButtonClick}
                    />
                  );
                })
              : null}
            {this.props.searchResults && this.props.searchResults.length ? (
              <Pagination
                pointing
                secondary
                siblingRange={2}
                firstItem={null}
                lastItem={null}
                onPageChange={(e) => this.props.handlePaginationChange(e)}
                activePage={this.props.page}
                totalPages={this.props.pageCount}
                style={{ marginBottom: 100, marginTop: 5 }}
              />
            ) : (
              <div />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderFilterStringLabel = () => {
    if (this.props.showFilterStringLabel && this.props.filterString) {
      return (
        <Label
          style={{ margin: 5, backgroundColor: "#216193", color: "white" }}
          as="a"
          content={`Search Text: ${this.props.filterString}`}
          key="filter-string-label"
          onRemove={() => this.props.clearFilterString()}
        />
      );
    } else {
      return <div />;
    }
  };
  render() {
    const resultsView = !this.props.__loadingSearchResults__ ? (
      this.renderSearchResults()
    ) : (
      <div className="center">
        <Dimmer
          active={true}
          inverted
          style={{ width: "100%", height: "100%", paddingTop: 100 }}>
          <CircularProgress
            style={{ color: "grey", marginTop: 140, paddingBottom: 30 }}
            thickness={4}
            size={50}
          />
        </Dimmer>
      </div>
    );

    return (
      <Card style={{ height: this.props.windowHeight, overflowY: "scroll" }}>
        <CardContent>{resultsView}</CardContent>
      </Card>
    );
  }
}
