/* eslint-disable eqeqeq */
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import { withMobileDialog } from "@material-ui/core";

class OfficeHours extends Component {
  renderOfficeHours = () => {
    if (this.props.hours.length > 0) return this.renderYesOfficeHours();
    if (this.props.hours.length == 0) return this.renderNoOfficeHours();
  };
  renderNoOfficeHours = () => {
    return <p>Contact provider’s office for hours</p>;
  };
  renderYesOfficeHours = () => {
    var mondayHoursString = "Closed";
    var tuesdayHoursString = "Closed";
    var wednesdayHoursString = "Closed";
    var thursdayHoursString = "Closed";
    var fridayHoursString = "Closed";
    var saturdayHoursString = "Closed";
    var sundayHoursString = "Closed";
    this.props.hours.forEach(function (h) {
      if (h.dow == "1") {
        mondayHoursString =
          moment(h.open_Time, "hh:mm a").format("LT") +
          " - " +
          moment(h.close_Time, "hh:mm a").format("LT");
      }

      if (h.dow == "2") {
        tuesdayHoursString =
          moment(h.open_Time, "hh:mm a").format("LT") +
          " - " +
          moment(h.close_Time, "hh:mm a").format("LT");
      }

      if (h.dow == "3") {
        wednesdayHoursString =
          moment(h.open_Time, "hh:mm a").format("LT") +
          " - " +
          moment(h.close_Time, "hh:mm a").format("LT");
      }

      if (h.dow == "4") {
        thursdayHoursString =
          moment(h.open_Time, "hh:mm a").format("LT") +
          " - " +
          moment(h.close_Time, "hh:mm a").format("LT");
      }

      if (h.dow == "5") {
        fridayHoursString =
          moment(h.open_Time, "hh:mm a").format("LT") +
          " - " +
          moment(h.close_Time, "hh:mm a").format("LT");
      }

      if (h.dow == "6") {
        saturdayHoursString =
          moment(h.open_Time, "hh:mm a").format("LT") +
          " - " +
          moment(h.close_Time, "hh:mm a").format("LT");
      }

      if (h.dow == "7") {
        sundayHoursString =
          moment(h.open_Time, "hh:mm a").format("LT") +
          " - " +
          moment(h.close_Time, "hh:mm a").format("LT");
      }
    });
    return (
      <Grid container alignContent="center" justify="center" wrap="wrap">
        <Grid item xs={4}>
          <p>Mon:</p>
        </Grid>
        <Grid item xs={8}>
          <p>{mondayHoursString}</p>
        </Grid>
        <Grid item xs={4}>
          <p>Tue:</p>
        </Grid>
        <Grid item xs={8}>
          <p>{tuesdayHoursString}</p>
        </Grid>
        <Grid item xs={4}>
          <p>Wed:</p>
        </Grid>
        <Grid item xs={8}>
          <p>{wednesdayHoursString}</p>
        </Grid>
        <Grid item xs={4}>
          <p>Thu:</p>
        </Grid>
        <Grid item xs={8}>
          <p>{thursdayHoursString}</p>
        </Grid>
        <Grid item xs={4}>
          <p>Fri:</p>
        </Grid>
        <Grid item xs={8}>
          <p>{fridayHoursString}</p>
        </Grid>
        <Grid item xs={4}>
          <p>Sat:</p>
        </Grid>
        <Grid item xs={8}>
          <p>{saturdayHoursString}</p>
        </Grid>
        <Grid item xs={4}>
          <p>Sun:</p>
        </Grid>
        <Grid item xs={8}>
          <p>{sundayHoursString}</p>
        </Grid>
      </Grid>
    );
  };

  render() {
    return <div>{this.renderOfficeHours()}</div>;
  }
}

class OfficeHoursModal extends Component {
  state = {
    open: false,
    hours: [],
  };

  handleOpen = () => {
    this.setState({ open: true });
    this.fetchBusinessHours(
      this.props.provider.provProfessionalPracticeRelationshipId,
    );
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  fetchBusinessHours = (id) => {
    this.providerApiPath = process.env.REACT_APP_API_URL + "/providerByGroup";
    return fetch(this.providerApiPath + "/hours?id=" + id, {
      method: "get",
      headers: {
        dataType: "json",
        contentType: "application/json",
      },
    })
      .then((response) => {
        response.json().then((hours) => {
          this.setState({
            hours,
          });
        });
      })
      .catch((err) => {
        console.log("Error fetching business hours: ");
        this.setState({
          isErrView: { flag: true, err: err },
        });
      });
  };
  render() {
    return (
      <div>
        <Button color="primary" variant="contained" onClick={this.handleOpen}>
          Office Hours
        </Button>
        {/* <OpenInNewIcon /> */}

        <Dialog
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}>
          <DialogTitle>Office Hours</DialogTitle>
          <DialogContent>
            <OfficeHours hours={this.state.hours} />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(OfficeHoursModal);
