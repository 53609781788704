import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import API from "../api";
import { FormMode } from "./enums";

// ######
// Styles
// ######
const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  dialogTitle: {
    marginBottom: 0,
    paddingBottom: 0,
  },
  dialogContextText: {
    marginBottom: 0,
    paddingBottom: 0,
  },
  dialogContent: {
    marginTop: 0,
    paddingTop: 0,
  },
  formControl: {
    marginTop: "16px",
    marginRight: "8px",
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: "16px",
  },
  textArea: {
    marginTop: "16px",
    marginRight: "8px",
  },
  textField: {
    marginTop: "16px",
    marginRight: "8px",
  },
  dateField: {
    marginTop: "16px",
    marginRight: "8px",
    width: 150,
  },
  menu: {
    width: 200,
  },
  wrapper: {
    margin: "8px",
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
};

class FormBase extends Component {
  API_CALL_POINT = "";
  ON_CREATE_IGNORE_ID = true;

  state = {
    data: {},
    errors: {},
  };

  constructor(props, apiEndpoint) {
    super(props);
    this.API_CALL_POINT = Object.freeze(apiEndpoint);
  }

  // #####################################################
  // Lifecycle Events.
  // Overwrite these in derived classes, when required.
  // #####################################################

  doSubmit = (entity) => {
    // No need to do anything...
  };

  doCreate = (entity) => {
    if ("onCreate" in this.props) this.props.onCreate(entity);
  };

  doUpdate = (entity) => {
    if ("onUpdate" in this.props) this.props.onUpdate(entity);
  };

  doDelete = (entityId) => {
    if ("onDelete" in this.props) this.props.onDelete(entityId);
  };

  doDialogClose = () => {
    if ("onDialogClose" in this.props) this.props.onDialogClose();
  };

  doFormSubmitting = (isFormSubmitting) => {
    if ("setFormSubmitting" in this.props)
      this.props.setFormSubmitting(isFormSubmitting);
  };

  doFormDeleting = (isFormDeleting) => {
    if ("setFormDeleting" in this.props)
      this.props.setFormDeleting(isFormDeleting);
  };

  // ##########################
  // Form tracking & Validation
  // ##########################

  validate = () => {
    // SHORT CIRCUIT /  DISABLE FEATURE
    return null;

    // const options = { abortEarly: false };
    // const { error } = Joi.validate(this.state.data, this.schema, options);
    // if (!error) return null;

    // const errors = {};
    // for (let item of error.details) errors[item.path[0]] = item.message;
    // return errors;
  };

  validateProperty = ({ name, value }) => {
    // SHORT CIRCUIT / DISABLE FEATURE
    return null;

    // const obj = { [name]: value };
    // const schema = { [name]: this.schema[name] };
    // const { error } = Joi.validate(obj, schema);
    // return error ? error.details[0].message : null;
  };

  handleChange = ({ target: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  handleCheck = ({ target: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = !!input.checked;

    this.setState({ data, errors });
  };

  // ############
  // CRUD actions
  // ############
  handleSubmit = (formMode) => {
    switch (formMode) {
      case FormMode.Create:
      case FormMode.Submit:
        this.handleCreate(this.state.data);
        break;
      case FormMode.Edit:
        this.handleUpdate(this.state.data);
        break;
      // no default
    }
    this.doSubmit();
  };

  // CREATE (http|POST)
  handleCreate = (entity) => {
    // Validate
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doFormSubmitting(true);

    // Clone entity for a clean submission.
    const newEntity = entity;
    if (this.ON_CREATE_IGNORE_ID) delete newEntity.id; // clear "id" field.

    // Call endpoint.
    API.post(this.API_CALL_POINT, newEntity)
      .then((result) => {
        // On a successful response, we will call the `doCreate`
        // with the resulting entity from the endpoint.
        this.doCreate(result.data);
        this.doDialogClose();
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        this.doFormSubmitting(false);
      });
  };

  // UPDATE (http|PUT)
  handleUpdate = (entity) => {
    // Validate
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doFormSubmitting(true);

    // Update entity via API
    API.put(`${this.API_CALL_POINT}/${entity.id}`, entity)
      .then((result) => {
        // On a successful response: we will call `doUpdate` in the
        // derived class, with the resulting entity from the endpoint.
        this.doUpdate(result.data);
        this.doDialogClose();
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        this.doFormSubmitting(false);
      });
  };

  // DELETE (http|DELETE)
  handleDelete = () => {
    if (!this.state.data.id) return;

    this.doFormDeleting(true);
    // Delete entity using backend API
    API.delete(`${this.API_CALL_POINT}/${this.state.data.id}`)
      .then(() => {
        // On a successful response: we will call `onDelete` in the
        // derived class, with the deleted entities id.
        this.doDelete(this.state.data.id);
        this.doDialogClose();
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        this.doFormDeleting(false);
      });
  };

  // ####################################
  // Renders for predefined form elements
  // ####################################

  renderStatusSelect(name, label) {
    const { data } = this.state;

    return (
      <FormControl style={styles.formControl}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Select
          name={name}
          value={data[name]}
          label={label}
          onChange={this.handleChange}
          inputProps={{
            name: name,
            id: name,
          }}>
          <MenuItem value={0}>TBD</MenuItem>
          <MenuItem
            value={1}
            style={{
              backgroundColor: "green",
              color: "#FFF",
              fontWeight: "bold",
            }}>
            Green
          </MenuItem>
          <MenuItem
            value={2}
            style={{
              backgroundColor: "darkorange",
              color: "#FFF",
              fontWeight: "bold",
            }}>
            Amber
          </MenuItem>
          <MenuItem
            value={3}
            style={{
              backgroundColor: "darkred",
              color: "#FFF",
              fontWeight: "bold",
            }}>
            Red
          </MenuItem>
          <MenuItem value={4}>ON HOLD</MenuItem>
          <MenuItem
            value={5}
            style={{
              backgroundColor: "#e1e1e1",
              color: "#a6a6a6",
            }}>
            <em>COMPLETE</em>
          </MenuItem>
        </Select>
      </FormControl>
    );
  }

  renderLevelSelect(name, label) {
    const { data, errors } = this.state;
    //const classes = useStyles();

    return (
      <FormControl style={styles.formControl}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Select
          name={name}
          value={data[name] || ""}
          onChange={this.handleChange}
          error={errors[name]}>
          <MenuItem value={0}>Low</MenuItem>
          <MenuItem value={1}>Medium</MenuItem>
          <MenuItem value={2}>High</MenuItem>
        </Select>
      </FormControl>
    );
  }

  renderTextInput(name, label, type = "text", customStyles = {}) {
    const { data, errors } = this.state;

    return (
      <TextField
        name={name}
        value={data[name] || ""}
        label={label}
        placeholder={label}
        type={type}
        onChange={this.handleChange}
        InputLabelProps={{
          shrink: true,
        }}
        style={{ width: "400px", ...customStyles, ...styles.textField }}
        error={errors[name]}
      />
    );
  }

  renderDateInput(name, label) {
    const { data, errors } = this.state;

    return (
      <TextField
        name={name}
        value={
          moment(data[name]).isValid()
            ? moment(data[name]).format("YYYY-MM-DD")
            : ""
        }
        label={label}
        type="date"
        style={styles.dateField}
        onChange={this.handleChange}
        InputLabelProps={{
          shrink: true,
        }}
        error={errors[name]}
      />
    );
  }

  renderTextareaInput(name, label, type = "text") {
    const { data, errors } = this.state;

    return (
      <TextField
        multiline
        fullWidth
        rows={4}
        name={name}
        value={data[name] || ""}
        label={label}
        placeholder="..."
        type={type}
        style={styles.textArea}
        onChange={this.handleChange}
        InputLabelProps={{
          shrink: true,
        }}
        error={errors[name]}
      />
    );
  }

  /**
   * Expects `data` in the format:
   *
   * data
   *  |-- name "categoryFieldName"
   *  |-- collection[0] "collectionFieldName"
   *        |-- name    "itemFieldName"
   *        |-- value   "itemFieldValue"
   *  |-- collection[1] "collectionFieldName"
   *        |-- name    "itemFieldName"
   *        |-- value   "itemFieldValue"
   *
   */
  renderGroupedSelect(
    name,
    label,
    selectData,
    collectionFieldName,
    categoryFieldName = "name",
    itemFieldName = "name",
    itemFieldValue = "id",
    placeholderText = "Select an option",
  ) {
    const { data, errors } = this.state;

    return (
      <FormControl style={styles.formControl}>
        <InputLabel shrink htmlFor={name}>
          {label}
        </InputLabel>
        <Select
          native
          name={name}
          value={data[name] || ""}
          onChange={this.handleChange}
          error={errors[name]}>
          <option value="" disabled>
            {placeholderText}
          </option>
          {selectData.length > 0 &&
            _.orderBy(selectData, categoryFieldName).map((x) => (
              <optgroup key={x[categoryFieldName]} label={x[categoryFieldName]}>
                {x[collectionFieldName].length > 0 &&
                  _.orderBy(x[collectionFieldName], itemFieldName).map((y) => (
                    <option key={y[itemFieldValue]} value={y[itemFieldValue]}>
                      {y[itemFieldName]}
                    </option>
                  ))}
              </optgroup>
            ))}
        </Select>
      </FormControl>
    );
  }

  renderSelect(
    name,
    label,
    selectData,
    // itemFieldName = "name",
    // itemFieldValue = "id",
    placeholderText = "Select an option",
  ) {
    const { data, errors } = this.state;

    return (
      <FormControl style={styles.formControl}>
        <InputLabel shrink htmlFor={name}>
          {label}
        </InputLabel>
        <Select
          native
          name={name}
          value={data[name] || ""}
          onChange={this.handleChange}
          error={errors[name]}
          inputProps={{
            name: name,
            id: name,
          }}>
          <option value="" disabled>
            {placeholderText}
          </option>
          {selectData.length > 0 &&
            selectData.map((x) => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
        </Select>
      </FormControl>
    );
  }
}

export default FormBase;
